// override bootstrap 4 variables

// $blue: #2196f3;
// $indigo: #536dfe;
// $purple: #9c27b0;
// $pink: #ff4081;
// $red: #f44336;
// $orange: #ff9800;
// $yellow: #ffeb3b;
// $green: #4caf50;
// $teal: #009688;
// $cyan: #00bcd4;
// $white: #fff;
$theme-colors: (
  'primary': #6a82fb,
  'secondary': #fc5c7d,
  'success': #7cd89c,
  //#45b649,
  'danger': #d0281b,
  //#f85032,
  'warning': #ffda83,
  //#ffd700,
  'info': #00c9ff,
  'bob': #d0281b,
);

$enable-gradients: true;

// card
//
$card-cap-bg: #fff;

// Button colors
$btn-ok: #d4281c;
$btn-cancel: #d4281c;
$btn-disabled: #e0e0e0;
$btn-text: #fff;

// React - Notification
$react-notification-text: #fff;

// General
$text-color-red: #d0281b;
