// Common Chat Components

// Chat button (FAB)
.chat-fab {
  position: fixed !important;
  bottom: 20px;
  right: 20px;
  border-radius: 50% !important;
  background-color: #d0281b !important;
  color: white !important;
  opacity: 0.5 !important;
  transition: opacity 0.3s ease-in-out !important;
  will-change: opacity;

  &:hover,
  &.chat-open {
    opacity: 1 !important;
  }
}

// Chat window
.chat-window {
  display: flex;
  position: fixed;
  bottom: 80px;
  top: auto;
  right: 20px;
  width: 500px;
  height: 700px;
  min-height: 500px;
  opacity: 0;
  visibility: hidden;
  flex-direction: column;
  z-index: 5000;
  will-change: opacity, visibility, top, bottom;
  transition: all 0.3s ease-in-out !important;

  &.open {
    opacity: 1 !important;
    visibility: visible;
    transition: all 0.3s ease-in-out !important;
  }

  &.full-screen {
    width: 80%;
    height: 65vh;
    margin: 0;
    border-radius: 0;
    z-index: 5000;
    top: 0;
    bottom: auto;
    flex-direction: row;
    will-change: bottom, top;
    transition: all 0.3s ease-in-out !important;

    .chat-header {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 10000;
      padding: 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      background-color: white;
    }

    .chat-body {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: auto;
      margin-top: 55px;

      .message-list {
        flex-grow: 1;
        padding: 16px;
      }
    }

    .input-area {
      margin-top: 55px;
      width: 50%;
      padding: 16px;
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      border-left: 1px solid rgba(0, 0, 0, 0.12);
      align-content: space-evenly;
    }
  }
}

// Common chat components
.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.chat-body {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  padding: 16px;
}

.message-list {
  flex-grow: 1;
  overflow: auto;
  padding: 16px;

  .thumbs-box {
    display: flex;
    justify-content: flex-start;
    padding-left: 70px;

    button {
      &.active,
      &:hover {
        > svg {
          color: green !important;
        }
      }

      &.active.thumbs-down-button,
      &:hover.thumbs-down-button {
        > svg {
          color: #d0281b !important;
        }
      }
    }
  }
}

.message-item {
  .avatar {
    padding: 0;
    overflow: hidden;

    &.bot {
      margin-right: 10px;
      align-self: start;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &.skeleton {
        margin: 0px;
      }
    }

    &.user {
      margin-left: 10px;
      color: white;
      background-color: #343434;
      order: 1;
      align-self: start;
    }
  }

  &.bot {
    justify-content: flex-start;
  }
  &.user {
    justify-content: flex-end;
  }
}

.message-bubble {
  padding: 8px;
  max-width: calc(100% - 100px);

  &.bot {
    background-color: #e0e0e0;
    color: black;
  }

  &.user {
    background-color: #d0281b;
    color: white;
  }
}

.input-area {
  padding: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);

  .Mui-disabled {
    background-color: #e0e0e0 !important;
  }

  textarea {
    margin-right: 15px;
    padding-right: 15px;
  }
}

// Common icon styles
.MuiIconButton-root {
  > svg {
    margin: 0 0.5rem;
    color: rgba(0, 0, 0, 0.54);

    &:hover {
      color: #d0281b;
      background-color: transparent !important;
    }
  }
}

// Common scrollbar style
.MuiList-root {
  .MuiListItem-root > .MuiPaper-root {
    word-wrap: break-word;
  }
}

// Loading skeleton
.MuiSkeleton-root {
  margin-left: 10px;
  width: calc(100% - 100px);
  opacity: 1;
}

// New session options
.new-session-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;

  .MuiButtonBase-root {
    margin-bottom: 5px;
  }
}

// Session chip style
.session-chip {
  margin: 4px;
  border-radius: 16px !important;
  background-color: #f5f5f5 !important;

  &:hover {
    background-color: #d0281b !important;
    color: white !important;
    cursor: pointer;
  }
}
