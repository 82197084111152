.siteLanding {
  background-color: #fff;
  width: 100vw;
  height: 100vh;

  .loading {
    width: 256px;
    height: 256px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .loadingTxt {
    margin-top: -30px;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }
  .process {
    font-size: 0.8rem;
    text-align: center;
  }

  .error {
    font-size: 1.2rem;
    font-weight: bold;
    padding-top: 40vh;
    text-align: center;
  }

  .login {
    position: absolute;
    right: 20px;

    button {
      background-color: #d4281c;
      border: none;
      outline: none;
      color: #fff;
    }
  }

  .login-form {
    width: 400px;
    min-height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px #606060 solid;
    border-radius: 5%;
    padding: 10px;

    &.floater {
      min-height: 230px;
    }

    div:first-child {
      text-align: center;
    }
    img {
      margin-left: auto;
      margin-right: auto;
      width: 250px;
      margin-bottom: 10px;
    }
    .title {
      font-size: 1.3rem;
      font-weight: bold;
      text-align: center;
    }

    .msg {
      color: #d4281c;
    }

    .sep-line {
      display: flex;
      flex-direction: row;
    }

    .sep-line::before,
    .sep-line::after {
      content: '';
      flex: 1 1;
      border-bottom: 1px solid #f0f0f0;
      margin: auto;
    }

    .login-field-wrapper {
      margin-top: 5px;

      span {
        font-size: 1rem;
        font-weight: bold;
      }
    }

    .region {
      margin-top: 10px;

      .region-check {
        > div {
          text-align: left !important;
        }

        > select {
          appearance: auto !important;
        }

        div:first-child {
          text-align: left !important;
        }

        button {
          z-index: -1;
          margin-top: 5px;
          margin-bottom: 5px;
          text-align: left;
          width: 100%;
          padding: 0.375rem 0.75rem;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          color: #212529;
          background-color: #fff;
          background-clip: padding-box;
          // border: 1px solid #ced4da;
          appearance: none;
          border-radius: 0.25rem;
          transition:
            border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
        }
      }
    }

    .store {
      .store-check {
        > div {
          text-align: left;
        }

        > select {
          appearance: auto !important;
        }
      }
    }

    .iss-login-button {
      position: relative;
      width: 100%;
      left: 0px;
      margin-top: 15px;
    }
  }

  .dev_test {
    position: absolute;
    top: 70px;
    left: 10px;
  }
}

.clearcache {
  cursor: pointer;
  font-size: 0.8rem;
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 99;
}
