.disposition-question-page-header {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin: 1rem;

  .dropdown-select {
    margin-left: 0px;
    margin-top: 0px;
    margin-right: 1rem;
  }

  .button-red {
    margin-left: auto;
    margin-top: 0px;
  }
}

.setup-table-disposition {
  margin: 1rem;
  padding: 0px 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow:
    0 0 1px rgba(0, 0, 0, 0.2),
    0 1px 5px rgba(0, 0, 0, 0.2);
  border: 1px solid lightgray;

  .scrollable-list {
    max-height: 200px;
    overflow-y: auto;
  }

  .scrollable-list div {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .scrollable-list::-webkit-scrollbar {
    width: 5px;
  }

  .scrollable-list::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
  }

  .scrollable-list::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  table {
    thead {
      tr {
        border-bottom: 1px solid black !important;
        th {
          text-align: left;
        }
      }
    }

    tbody {
      tr {
        &:hover {
          background-color: #f9ebe9;
        }

        .center-cell {
          text-align: center;
        }

        .store-col {
          min-width: 200px;
        }

        //Actionable Column
        .actionable-column {
          width: 110px;
          text-align: left;

          svg {
            margin: 0px 5px;

            &:hover {
              cursor: pointer;
              color: #d0281b;
            }
          }
        }

        .no-data {
          text-align: center;
        }
      }
    }
  }
}

.user-question-modal {
  width: 40%;
  background-color: white;
  border-radius: 5px;

  .question-text {
    margin-top: 20px;
    font-weight: bold;

    &.required:not(.filled)::after {
      content: '*';
      color: red;
      margin-left: 5px;
    }
  }

  .modal-header {
    padding: 1rem;

    h5 {
      margin-bottom: 0;
      line-height: 1.5;
    }
  }

  .user-question-modal-button {
    margin-top: 20px;
    text-align: right;
    .skip-for-now-button {
      background-color: white !important;
      color: black !important;
      font-weight: 500;
    }
  }

  .MuiFormGroup-root {
    margin-left: 10px;
    margin-right: 0;

    .MuiFormControlLabel-root {
      margin-left: 0;
      margin-right: 0;
      margin-top: 10px;
      padding: 5px 20px;
      font-size: 1.1rem;
    }

    .MuiCheckbox-root,
    .MuiRadio-root {
      margin-right: 7px;
    }

    .MuiTextField-root {
      padding: 15px 0;
    }
  }
}

.disposition-question-modal {
  width: 90%;
  height: fit-content;
  position: fixed;
  top: 100px;
  // left: 5%;
  // right: 5%;
  place-self: center;

  .MuiBox-root {
    border: 1px solid black;
    border-radius: 5px;
    background-color: white;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .modal-header {
      padding: 1rem;
      border-bottom: 1px solid #dee2e6;

      h5 {
        margin-bottom: 0;
        line-height: 1.5;
        font-family: 'ARS Maquette Pro' !important;
        font-weight: 400;
      }
    }

    .modal-body {
      padding: 1rem;

      .MuiFormControl-root.MuiTextField-root {
        width: 80%;
        margin-right: 1rem;
        margin-top: 1rem;

        &.question-input {
          margin-left: 1rem;
        }

        .MuiInputBase-root {
          height: 45px;

          input {
            padding: 10px;
          }
        }
      }

      .dropdown-select {
        margin-left: 0px;
      }

      .MuiGrid-root.MuiGrid-container {
        margin: 1rem 0;
        padding: 0 1rem 0 0;

        .option-list-setting {
          overflow: scroll;
          max-height: calc(80vh / var(--scale-factor) - 250px);
          padding-right: 15px;

          .MuiIconButton-root {
            margin-top: -8px;
            float: right;
          }
        }

        .row-setting {
          display: flex;
          flex-direction: row;
          margin-bottom: 1rem;

          > div:first-child {
            width: 150px;
          }

          .location-setting {
            display: flex;
            width: 90%;
            justify-content: space-evenly;

            .dropdown-select {
              margin: 0 10px 0 0;
              width: 100% !important;
              min-width: unset !important;
            }

            .dropdown-select:nth-child(3) {
              margin-right: 0px;
            }
          }

          .close-opportunity-actions {
            display: grid;
            gap: 10px;
            width: 90%; // Ensure it takes the full width of the parent container
            grid-template-columns: 1fr 1fr 1fr 1fr;

            .MuiButtonBase-root {
              border: 1px solid rgba(0, 0, 0, 0.12);
              border-radius: 5px;
              width: 100%; // Make sure buttons fill their grid cell
              color: #212529;

              &:hover {
                background-color: #f9ebe9;
              }

              &.Mui-selected {
                color: white;
                background-color: #d4281c;
              }
            }
          }

          .Mui-checked > .MuiSwitch-thumb {
            color: #d4281c !important;
          }
          .Mui-checked + .MuiSwitch-track {
            background-color: #d4281c !important;
            opacity: 0.5 !important;
          }
        }
      }
    }

    .modal-footer {
      display: flex;
      margin-top: auto;
      margin-left: auto;
      text-align: end;
      border: none;
    }
  }
}
