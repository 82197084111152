.business-module-container {
  position: relative;
  z-index: 1000;
  // top: 10px;
  height: 100%;

  &.overlay-visible {
    .overlay {
      display: block;
    }
  }

  .overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  label {
    font-size: 1.3rem;
    font-weight: 700;
    text-align: center;
  }

  .module-popup {
    border: 2px solid grey;
    border-radius: 20px;
    position: fixed;
    right: 40px;
    top: 20px;
    width: 92%;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 15px;
    z-index: 1000;
    height: calc(100vh / var(--scale-factor) - 50px);
    overflow: hidden;

    @media only screen and (max-width: 1279px) {
      height: calc(100vh / var(--scale-factor) - 50px);
    }

    .MuiGrid-root {
      height: 100%;
      &.MuiGrid-container {
        margin-left: -15px !important;
        margin-top: -15px !important;
      }
    }
  }

  .app-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .iss-logo1 {
    width: 50%;
    margin: 0 auto;
  }

  //LEFT CONTENT
  .notifications-container {
    flex-wrap: wrap;
    border: 1px solid grey;
    border-radius: 10px;
    width: 100%;
    justify-content: center;
    text-align: left;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: -15px;
    margin-right: -10px;

    &.nooverflow {
      overflow: hidden !important;
    }

    .notifications-buttons {
      display: flex;
      justify-content: center;
    }

    .button-red {
      height: auto;
      min-width: auto;
    }

    .notifications-button {
      padding: 8px 15px;
      border-radius: 10px;
      border: none;
      cursor: pointer;
      font-weight: 500;
      &:hover {
        opacity: 0.5;
      }
    }
    .active {
      background-color: red;
      color: white;
    }

    .notifications-title {
      font-size: 1.1rem;
      font-weight: 500;
      margin-top: 10px;
      margin-bottom: 20px;
      text-align: center;
    }
    .notifications-body {
      display: flex;
      flex-direction: column;
      max-height: 100%;

      .notifications-detail {
        display: flex;
        align-items: center;
        padding: 10px;
        background-color: #f0f0f0;
        border-radius: 10px;
        margin: 10px;
        border: 1px solid lightgrey;
      }

      .notifications-icon {
        width: 100%;
        height: 100%;
        display: contents;
      }

      .notifications-message label {
        font-size: 14px;
        color: #333;
        text-align: start;
        cursor: pointer;
        transition: background-color 0.3s;
        margin-right: 4%;
      }

      .notifications-message {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 200%;
      }

      .notifications-message label:hover {
        background-color: lightgray;
      }

      .notiIcon {
        width: 20px;
        height: 20px;
        align-items: center;
        margin-left: 3%;
        margin-right: 5%;
        transform: translateY(10%);
        // margin-right: 20px;
        &:hover {
          opacity: 0.5;
        }
      }

      .notiIconValue {
        width: 45px;
        height: 45px;
        transform: translateY(0%);
        margin-right: 3%;
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  //CENTER CONTENT
  .header {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
    font-weight: 500;
    overflow: hidden;
  }

  .bucket-summary-container {
    position: relative;
    border: 1px solid grey;
    border-radius: 10px;
    padding: 10px;
    margin: 3px;
    width: 100%;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    align-items: center;
    justify-content: center;
    overflow-y: clip;
    p {
      margin-top: 20px;
      margin-bottom: 20px;
      font-weight: 500;
      text-align: center;
      position: relative;
      z-index: 2;
    }
    img {
      position: absolute;
      bottom: 0;
      max-width: auto;
      height: 25vh;
      transform: translateX(-50%);
      transition: height 0.3s ease;
      z-index: 1;
    }
  }

  .c-and-c-summary-container {
    border: 1px solid grey;
    border-radius: 10px;
    padding: 10px;
    margin: 3px;
    font-weight: 500;
    text-align: center;
    justify-content: center;
    overflow-y: auto;
  }

  //RIGHT CONTENT
  .release-notes-container,
  .store-pulse-container {
    position: relative;
    border: 1px solid grey;
    border-radius: 10px;
    padding: 10px;
    margin: 3px;
    font-weight: 500;
    justify-content: center;
    text-align: center;
    align-items: center;
    overflow: hidden;

    .button-red {
      min-width: auto;
    }

    .button-group {
      gap: 10px;
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
    }

    img {
      position: absolute;
      bottom: -0.5rem;
      right: -0.5rem;
      max-width: auto;
      max-height: 20vh;
      transition: max-height 0.3s ease;
      z-index: 1;
    }
    &::after {
      content: '';
      display: table;
      clear: both;
    }
  }

  .release-notes-container {
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 20vh;
      text-align: center;
      flex-wrap: wrap;
      b {
        padding: 0.3rem;
      }
    }
  }

  .store-pulse-container {
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 30vh;
      text-align: center;
      flex-wrap: wrap;
    }
  }

  //FEEDBACK MODAL
  .feedback-module,
  .mute-module {
    border: 1px solid grey;
    border-radius: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    gap: 10px;
    padding: 20px;
    z-index: 1500;

    p {
      margin-bottom: 1px;
    }

    .feedback-module_reasons {
      .custom-option {
        width: 100%;
      }
      .custom-option.selected {
        width: 100%;
      }
      .row-input__menu {
        width: 100%;
        border: 1px solid grey;
        border-radius: 5px;
      }
      .row-input {
        width: auto;
        margin-bottom: 20px;
      }
    }

    .exit-button {
      position: absolute;
      right: 10px;
      top: 10px;
      background-color: transparent;
      border: none;
      cursor: pointer;
    }

    .submit-button-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }

  .feedback-module h3,
  .feedback-module label,
  .feedback-module select,
  .feedback-module textarea,
  .mute-module label {
    margin: 10px;
  }

  .feedback-module h3 {
    text-align: center;
    font-weight: 600;
    margin-bottom: 30px;
  }

  .feedback-module select,
  .feedback-module textarea,
  .mute-module textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid grey;
    border-radius: 5px;
  }
}
