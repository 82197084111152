.customer360 {
  .modal-content {
    height: 90vh;
  }

  span,
  div,
  svg {
    padding: 0;
  }

  @media only screen and (max-width: 1500px) {
    font-size: 0.75rem;
  }

  @media only screen and (min-width: 1500px) {
    font-size: 1rem;
  }

  @media only screen and (min-width: 2000px) {
    //font-size: 1.4rem;
  }

  .loading {
    text-align: center;
  }

  td.body {
    padding: 5px;
  }
  .topBar {
    @media only screen and (max-width: 1599px) {
      height: 65px;
    }

    @media only screen and (min-width: 1600px) {
      height: 80px;
    }

    @media only screen and (min-width: 2048px) {
      height: 90px;
    }
    // padding-bottom: 10px!important;

    .hyperlink {
      color: blue;
      // cursor: pointer;
    }

    > td {
      padding: 0;
      > div {
        border-bottom: 1px solid #666;
        padding: 10px;

        > span {
          display: inline-block;
          vertical-align: top;
          margin-left: 5px;

          &.name {
            width: 14%;
          }

          &.id {
            width: 10%;
          }

          &.lastOrderStatus {
            width: 14%;
          }

          &.phone {
            width: 17%;
          }

          &.lastOrder {
            width: 17%;
          }

          &.email {
            width: 24%;
            div {
              text-overflow: ellipsis;
              overflow-x: hidden;
            }
          }

          div:first-child {
            color: #666;
          }
        }
      }
    }

    .custom-modal-btn-close {
      position: relative;
      margin-right: -20px;

      @media only screen and (max-width: 1542px) {
        margin-top: -48px;
      }

      @media only screen and (min-width: 1543px) {
        margin-top: 0px;
      }
    }
  }

  .leftBar {
    > div {
      // border-right: 1px solid #666;
      height: 100%;
      overflow-y: scroll;

      &.loading {
        padding: 100px;
      }
    }

    .highlights {
      table-layout: fixed;
      border-collapse: separate;
      border-spacing: 15px;
      // width: 85%;

      .likelineesToCancel {
        height: 3vw;
        border: none !important;
        letter-spacing: -0.05em;

        font-size: 0.55rem;
        font-weight: bold;
        @media only screen and (min-width: 1600px) {
          font-size: 0.65rem;
        }

        @media only screen and (min-width: 2000px) {
          font-size: 0.75rem;
        }

        @media only screen and (min-width: 2200px) {
          font-size: 1rem;
        }

        &.high {
          background-color: #d4281c;
          color: white;
        }

        &.medium {
          background-color: #ffd966;
        }

        img {
          padding: 0;
          // margin-right: 5px;
          @media only screen and (min-width: 2200px) {
            width: 4rem;
          }
        }
      }

      .blue {
        color: blue;
      }
      td {
        border: 2px solid #d9d9d9 !important;
        background-color: #fafafa;
        width: 6vw;
        height: 6vw;
        position: relative;
        white-space: nowrap;
        text-align: center;

        div {
          position: absolute;
          padding: 0;
          display: block;
          width: 100%;
          text-align: center;
        }

        .value {
          top: 0.8vw;
          font-size: 1.4rem;
        }

        .title {
          top: 3.5vw;
          font-size: 0.6rem;
          line-height: 1;
          opacity: 0.6;
        }

        &.clickable {
          cursor: pointer;
          &.googleRating .value {
            color: blue;
          }
        }

        @media only screen and (min-width: 1600px) {
          .value {
            top: 0.9vw;
            font-size: 2rem;
          }

          .title {
            font-size: 0.8rem;
          }
        }

        @media only screen and (min-width: 2000px) {
          .value {
            top: 1vw;
            font-size: 2.5rem;
          }

          .title {
            top: 3.8vw;
            font-size: 0.8rem;
            line-height: 1;
          }
        }

        @media only screen and (min-width: 2200px) {
          .value {
            top: 1vw;
            font-size: 2.8rem;
          }

          .title {
            top: 3.8vw;
            font-size: 1rem;
            line-height: 0.9;
          }
        }

        // padding: 10px 0;

        //display: inline-block;

        /*

                @media only screen and (max-width: 1500px) {
                    // font-size: 0.6rem;
                    width: 75px;
                    height: 75px;
                    padding-top: 10px;
                    .title{font-size: 0.6rem;}
                    .value{font-size: 1.4rem;}
                    &.totalSales{
                        padding-top: 1.2rem;
                        .value{font-size: 1rem;}
                        // .title{margin-left: -5px;}

                    }
                    &.storePurchaserNPS, &.googleRating{
                        .title{ font-size: 0.45rem; }
                    }

                    // &.googleRating{
                    //     .title{margin-left: -5px;}
                    // }
                }

                @media only screen and (min-width: 1500px) {
                    // font-size: 0.7rem;
                    width: 90px;
                    height: 90px;
                    padding-top: 0.6rem;
                    .title{font-size: 0.7rem;}
                    .value{font-size: 2.0rem;}
                    &.totalSales{
                        padding-top: 1.5rem;
                        .value{font-size: 1.3rem;}
                    }
                    &.storePurchaserNPS{
                        // padding: 0.6rem 0.2rem;
                        padding-top: 13px;
                        .title{ font-size: 0.5rem;}
                        .value{font-size: 1.7rem;}
                        svg{
                            height: 45px;
                            width:  45px;
                        }
                    }

                    &.googleRating{
                        padding-top: 13px;
                        .title{ font-size: 0.6rem;}
                        .value{font-size: 1.7rem;}
                    }
                }

                @media only screen and (min-width: 1800px) {
                    // font-size: 0.7rem;
                    width: 100px;
                    height:100px;
                    padding-top: 0.6rem;
                    .title{font-size: 1rem;}
                    .value{font-size: 2.3rem;}
                    &.totalSales{
                        padding-top: 1.5rem;
                        .value{font-size: 1.5rem;}
                        .title{font-size: 0.8rem;}

                    }
                    &.storePurchaserNPS{
                        // padding: 0.6rem 0.2rem;
                        // padding-top: 13px;
                        .title{ font-size: 0.6rem;}
                        .value{font-size: 2.0rem;}

                        svg{
                            height: 45px;
                            width:  45px;
                        }
                    }

                    &.googleRating{
                        // padding-top: 13px;
                        .title{ font-size: 0.7rem;}
                        .value{font-size: 2.0rem;}
                    }
                }

                @media only screen and (min-width: 2048px) {
                    // font-size: 0.75rem;
                    width: 110px;
                    height: 110px;
                    padding-top: 0.6rem;
                    .title{font-size: 1rem;}
                    .value{font-size: 2.5rem;}
                    &.totalSales{
                        padding-top: 1.5rem;
                        .value{font-size: 1.7rem;}
                    }
                    &.storePurchaserNPS{
                        // padding:  0.75rem;
                        .title{ font-size: 0.65rem;}
                        .value{font-size: 2.1rem;}

                        svg{
                            height: 50px;
                            width:  50px;
                        }
                    }

                    &.googleRating{
                        // padding-top: 13px;
                        .title{ font-size: 0.75rem;}
                        .value{font-size: 2.1rem;}
                    }
                }

                @media only screen and (min-width: 3440px) {
                    // font-size: 0.75rem;
                    width: 160px;
                    height: 160px;
                    padding-top: 0.6rem;
                    .title{font-size: 1.5rem;}
                    .value{font-size: 3.6rem;}
                    &.totalSales{
                        padding-top: 1.5rem;
                        .value{font-size: 2.5rem;}
                        .title{font-size: 1.3rem;}

                    }
                    &.storePurchaserNPS{
                        padding-top:  1rem;
                        .title{ font-size: 0.9rem; margin-left: -3px;}
                        .value{ font-size: 3rem;}
                        svg{
                            height: 75px;
                            width:  75px;
                        }
                    }

                    &.googleRating{
                        // padding-top: 13px;
                        .title{ font-size: 1.1rem; margin-left: -5px;}
                        .value{ font-size: 3rem;}
                    }
                }*/

        .avgNPSSad,
        .likelinessHigh {
          color: #d4281c;
        }
        .avgNPSMeh,
        .likelinessModerate {
          color: #f3d900;
        }
        .avgNPSHappy {
          color: #7cd89c;
        }
      }

      // .storePurchaserNPS{

      //     @media only screen and (max-width: 1500px) {
      //         padding: 0.2rem;
      //         .title{ font-size: 0.45rem;}
      //     }

      //     @media only screen and (min-width: 1500px) {
      //         padding: 0.6rem 0.2rem;
      //         .title{ font-size: 0.6rem;}
      //     }

      //     @media only screen and (min-width: 2000px) {
      //         padding:  0.75rem;
      //         .title{ font-size: 0.8rem;}
      //         svg{
      //             height: 40px;
      //             width:  40px;
      //         }

      //     }

      // }
    }
    .availableFunds {
      padding: 15px 20px;
      > div {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        // grid-column-gap: 10px;
        grid-gap: 15px;
        // place-items: center;
        // justify-items: center;
        // grid-template-rows: 6vw ;
        // display: flex;
        // align-items: center;
        // justify-content: space-between;

        > div {
          display: flex;
          position: relative;
          border: 2px solid #d9d9d9 !important;
          background-color: #fafafa;
          height: 6vw;
          width: 6.2vw;
          @media only screen and (min-width: 1600px) and (max-width: 1800px) {
            height: 6vw;
            width: 6.5vw;
          }
          box-sizing: border-box;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          div:first-child {
            font-size: 1.4rem;
            top: 0.8vw;
            text-align: center;
            position: absolute;
          }

          div:nth-child(2) {
            font-size: 0.6rem;
            opacity: 0.6;
            text-transform: uppercase;
            line-height: 1;
            text-align: center;
            top: 3.5vw;
            position: absolute;
          }

          @media only screen and (min-width: 1600px) {
            div:first-child {
              top: 0.9vw;
              font-size: 2rem;
            }

            div:nth-child(2) {
              font-size: 0.8rem;
            }
          }

          @media only screen and (min-width: 2000px) {
            div:first-child {
              top: 1vw;
              font-size: 2.5rem;
            }

            div:nth-child(2) {
              top: 3.8vw;
              font-size: 0.8rem;
              line-height: 1;
            }
          }

          @media only screen and (min-width: 2200px) {
            div:first-child {
              top: 1vw;
              font-size: 2.8rem;
            }

            div:nth-child(2) {
              top: 3.8vw;
              font-size: 1rem;
              line-height: 0.9;
            }
          }
        }
      }
    }

    .aboutThisCustomer {
      .blue {
        color: blue;
      }

      .table-responsive {
        background-color: #f3f3f3;
        width: 90%;
        margin: 10px auto;
        padding: 10px;

        thead {
          text-align: center;
        }

        th {
          @media only screen and (min-width: 1500px) {
            width: 200px;
          }
        }

        img {
          // display: inline-block;
          width: 20px;
          height: 20px;
          padding: 0;
          margin-left: 10px;
          cursor: pointer;
        }

        .room {
          tr:first-child th {
            text-align: center;
          }
          td {
            text-align: right;
          }
        }

        .contactPreference {
          @media only screen and (max-width: 1500px) {
            font-size: 0.75rem;
          }

          @media only screen and (min-width: 1500px) {
            font-size: 1rem;
            max-width: 130px;
          }
          color: blue;
          padding: 3px 6px;
          width: fit-content;
          max-width: 100px;
          display: inline-block;
          text-align: left;

          &.display {
            border: none;
            text-align: left;
            padding: 0;
          }

          &.form-control:disabled {
            background-color: transparent;
          }
        }
      }
    }
  }

  // .rightBar{ height: 400px; background-color: red; }

  .right1 {
    border: 2px solid #bcbcbc !important;
    border-top: 1px solid #bcbcbc !important;
    // border-right: 1px solid #BCBCBC !important;
    // border-bottom: 1px solid #BCBCBC   !important;
    display: inline-block;
    margin-bottom: 5px;
    width: 100%;
    // height: 100px;

    .content {
      height: 110px;
      overflow: hidden;
      margin: 5px;
      padding: 0px 10px;

      label {
        font-size: 0.9rem;
      }

      .orderSelect {
        // margin: 5px 0;
        select {
          appearance: auto;
          max-width: 200px;
          margin-bottom: 5px;
          margin-left: 10px;
          display: inline-block;
        }

        label {
          margin-left: 10px;
        }

        .test {
          appearance: auto;
          max-width: 250px;
          margin-bottom: 5px;
          margin-left: 10px;
          display: inline-block;
          cursor: pointer;
        }

        .datepick {
          appearance: auto;
          max-width: 250px;
          display: inline-block;
          margin-bottom: 5px;
          margin-left: 10px;
          // width: 100%;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          color: #212529;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #ced4da !important;
          border-radius: 0.25rem;
          padding: 0.3rem 0.7rem !important;

          select {
            margin-left: -5px !important;
          }
        }

        .react-datetime-picker__wrapper {
          border: 0 !important;
        }
      }

      @media only screen and (min-width: 2000px) {
        height: 130px;

        .orderSelect {
          margin: 12px 0;
        }
      }

      &.comment {
        .form {
          display: flex;

          textarea {
            display: inline-block;
            //width: 88%;
            //font-family: ARSMaquettePro !important;
            font-size: 0.9rem;
            color: #000000;
            height: calc(3.5rem) !important;
            // min-height: 80px;
            // max-height: 80px;
            // width: 100%;
            padding: 5px;
            resize: none;

            &:focus {
              outline: none;
            }
          }
        }
      }

      &.Follow-up {
        .form {
          display: flex;

          textarea {
            display: inline-block;
            //width: 88%;
            //font-family: ARSMaquettePro !important;
            font-size: 0.9rem;
            color: #000000;
            height: calc(3.5rem) !important;
            // min-height: 80px;
            // max-height: 80px;
            // width: 100%;
            padding: 5px;
            resize: none;

            &:focus {
              outline: none;
            }
          }
        }
      }

      .saveButton {
        border: none;
        background-color: #d4281c;
        color: white;
        border-radius: 5px;
        font-size: 1rem;
        padding: 0.4rem 1rem;
        margin-left: 10px;
        vertical-align: top;
        float: right;
      }

      .saveButtonBobSuite {
        border: none;
        background-color: #d4281c;
        color: white;
        border-radius: 5px;
        font-size: 1rem;
        padding: 0.4rem 1rem;
        margin-left: 10px;
        vertical-align: top;
        float: right;
      }
    }
  }

  //because just showing right 2, so the height value changed, if show all, can set height: 320 px
  .right2 {
    // height: calc(100vh * 0.75*0.5);
    padding: 0 !important;
    @media only screen and (max-width: 1800px) {
      width: 70%;
    }

    @media only screen and (min-width: 1800px) {
      width: 75%;
    }

    @media only screen and (min-width: 3440px) {
      width: 80%;
    }

    // @media only screen and (min-width: 2000px) {
    //     width: 76%;
    // }

    border-top: 2px solid #bcbcbc !important;
    border-left: 2px solid #bcbcbc !important;
    border-right: 1px solid #bcbcbc !important;
    border-bottom: 1px solid #bcbcbc !important;

    .OCTtable {
      width: 95%;

      td {
        padding: 0.75rem;

        &.fromSA {
          width: auto;
        }
        // white-space: nowrap;
        // overflow-x: hidden;
        // text-overflow: ellipsis;
        vertical-align: top;
      }

      @media only screen and (max-width: 1500px) {
        margin-left: 20px;
        td {
          padding: 0.75rem 0.25rem;
        }
        // .on{width: 130px;}
        .from,
        .to {
          max-width: 90px;
        }
      }

      @media only screen and (min-width: 1500px) {
        margin-left: 30px;
        td {
          padding: 0.75rem 0.15rem;
        }
        .from,
        .to {
          max-width: 100px;
        }

        // .on{width: 185px;}
        // .from, .to{width: 100px;}
      }

      @media only screen and (min-width: 1800px) {
        margin-left: 40px;
        td {
          padding: 0.75rem 0.25rem;
        }
        .from,
        .to {
          max-width: 180px;
        }

        // .on{width: 200px;}
        // .from, .to{width: 110px;}
      }

      @media only screen and (min-width: 2048px) {
        margin-left: 40px;
        .td {
          max-width: 260px;
        }
        // .on{width: 250px;}
        // .from, .to{width: 150px; white-space: nowrap; max-width: 350px;}
      }
    }

    .OCTrow {
      &.second {
        background-color: #f3f3f3;
      }

      // th, td{
      //     // @media only screen and (max-width: 1800px) {
      //         padding: 0.5rem 0.2rem!important;
      //     // }
      //     // max-width: 200px;
      //     // padding: 0;
      //     display: inline-block;
      // }

      .orderNumber {
        width: 10.5%;
        margin-left: 30px;
      }
      .line {
        width: fit-content;
      }
      .status {
        width: 11%;
      }
      .salesAssociate {
        width: 10%;
      }
      .profitCenter {
        width: 9%;
      }
      .date {
        width: 10%;
      }
      .time {
        width: 9%;
      }
      .old {
        width: 10.5%;
      }
      .new {
        width: 10.5%;
      }

      @media only screen and (min-width: 1500px) {
        .orderNumber {
          width: 10.5%;
          margin-left: 30px;
        }
        .line {
          width: fit-content;
        }
        .status {
          width: 13%;
        }
        .salesAssociate {
          width: 10%;
        }
        .profitCenter {
          width: 12%;
        }
        .date {
          width: 10%;
        }
        // .time{width: 9%;}
        .old {
          width: 10%;
        }
        .new {
          width: 10%;
        }
      }

      .blue {
        color: #1c4587;
      }

      .connector {
        color: #c4a6a6;
        width: fit-content;
        // text-align: center;
      }
    }

    .content {
      margin: 5px;
      height: calc(90vh - 130px - 130px - 110px);

      overflow-y: scroll;
      overflow-x: hidden;

      .hyperlink {
        color: blue;
      }

      .next,
      .month {
        // height: 100px;
        // overflow-y: scroll;

        // .title{width: 40%; display: inline-block;  padding: 0 5px;}
        .invisibleCarrat {
          width: 40px;
          display: inline-block;
        }

        .cust360-month,
        .cust360-next {
          margin-left: 32px;
          position: relative;
          background-color: transparent;
          z-index: 1;

          .cust360-next-row {
            // vertical-align: top;
            // margin-left: 32px;
            z-index: 1;
            // padding-top: 10px;
            // padding-bottom: 10px;
            position: relative;
            min-height: 20px;

            &.cursor {
              cursor: unset;
            }

            &:first-child {
              margin-top: 0px;
            }

            &:first-child::after {
              top: 20px;
            }

            &:last-child::after {
              height: 30px;
            }

            .cust360-row-content {
              position: relative;

              .icon {
                display: inline-block;
                width: 0px;
                text-align: center;
                img {
                  width: 35px;
                  vertical-align: unset;
                }
                // .img_container{background-color: white;}
                .timeline {
                  width: 30px;
                  border-right: 1.5px solid gray;
                  min-height: 10px;
                }
              }

              .showImg,
              .status,
              .time,
              .date {
                @media only screen and (max-width: 2048px) {
                  width: 13%;
                }

                @media only screen and (min-width: 2049px) {
                  width: 10%;
                }

                @media only screen and (min-width: 4000px) {
                  width: 5%;
                }
                display: inline-block;
                padding: 0 5px;
                width: 13%;
                min-width: 90px;
                text-align: center;
                vertical-align: top;
              }
              .status {
                //width: 9%;
                min-width: 63px;
                text-align: center;
                width: 0px;
                display: none;
              }
              .showImg {
                color: blue;
                cursor: pointer;
                text-decoration: underline;
              }

              .accordion__button {
                cursor: unset;
              }

              .accordion__button::before {
                cursor: pointer;
                position: absolute;
                color: grey;
                left: 0px;
                top: 5px;
                height: 12px;
                width: 12px;
              }

              .title {
                display: inline-block;
                white-space: normal;
                padding-left: 32px;

                &.link {
                  color: blue;
                  cursor: pointer;
                }

                @media only screen and (max-width: 2048px) {
                  width: 70%;
                }

                @media only screen and (min-width: 2049px) {
                  width: 71%;
                }

                @media only screen and (min-width: 4000px) {
                  width: 79%;
                }
                // min-width: 515px;
                // max-width: 515px;
              }

              div {
                vertical-align: top;
                white-space: normal;
                padding: 0px;
              }

              .clock {
                display: inline-block;
                padding: 0 5px;
                cursor: pointer;
                margin-left: -20px;
                margin-top: -4px;
                position: absolute;
              }

              .comment {
                margin-left: 50px;
                word-break: break-word;
                background: transparent;
                background-color: #f3f3f3;
                padding: 10px 20px;
                border-radius: 25px;
              }
            }

            .cust360-row-details {
              background: transparent;
              padding: 5px;

              .details {
                // padding-left: 100px;
                // word-break: break-word;
                margin-left: 50px;
                word-break: break-word;
                background: transparent;
                background-color: #f3f3f3;
                padding: 10px 20px;
                border-radius: 25px;
              }
            }
          }

          .cust360-row {
            z-index: 1;
            padding-top: 10px;
            padding-bottom: 10px;
            position: relative;
            min-height: 60px;

            &.cursor {
              cursor: unset;
            }

            &.flag-highlight {
              border: 1px solid red;
              border-radius: 5px;
            }

            &.temporary-highlight {
              animation: blinker 0.6s linear;
            }

            @keyframes blinker {
              0% {
                opacity: 1;
              }

              25% {
                opacity: 0;
              }

              50% {
                opacity: 1;
              }

              75% {
                opacity: 0;
              }

              100% {
                opacity: 1;
              }
            }

            &:first-child {
              margin-top: 0px;
            }

            &::after {
              content: ' ';
              position: absolute;
              left: 53px;
              top: 0px;
              height: 100%;
              width: 1px;
              background-color: gray;
              z-index: 0;
            }

            &:first-child::after {
              top: 20px;
            }

            &:last-child::after {
              height: 30px;
            }

            .cust360-row-content {
              position: relative;

              .icon {
                display: inline-block;
                text-align: center;
                position: absolute;
                width: 60px;
                left: 25px;
                padding: 2px;
                margin-top: -2px;
                background-color: #fff;
                z-index: 1;

                img {
                  width: 40px;
                  vertical-align: unset;
                }
              }

              .showImg,
              .status,
              .time,
              .date {
                @media only screen and (max-width: 2048px) {
                  width: 11%;
                }

                @media only screen and (min-width: 2049px) {
                  width: 10%;
                }

                @media only screen and (min-width: 4000px) {
                  width: 5%;
                }
                display: inline-block;
                padding: 0 5px;
                // min-width: 90px;
                text-align: right;
              }
              .status {
                width: 9%;
                min-width: 63px;
                text-align: center;
              }
              .showImg {
                color: blue;
                cursor: pointer;
                text-decoration: underline;
              }

              .accordion__button {
                cursor: unset;
              }

              .accordion__button::before {
                cursor: pointer;
                position: absolute;
                color: grey;
                left: 5px;
                top: 20px;
                height: 12px;
                width: 12px;
              }

              .title {
                @media only screen and (max-width: 2048px) {
                  width: 43%;
                }

                @media only screen and (min-width: 2049px) {
                  width: 56%;
                }

                @media only screen and (min-width: 4000px) {
                  width: 70%;
                }
                display: inline-block;
                margin-left: 85px;
                // min-width: 350px;
                // max-width: 560px;
              }

              div {
                vertical-align: top;
                white-space: normal;
                padding: 0px;
              }
            }

            .cust360-row-details {
              background: transparent;
              padding: 5px;

              .details {
                padding-left: 100px;
                word-break: break-word;

                .details_survey {
                  white-space: nowrap;

                  .icon {
                    width: 65px;
                    display: inline-block;
                    vertical-align: top;
                  }
                  .text {
                    width: 80px;
                    display: inline-block;
                    vertical-align: top;
                  }
                  .score {
                    width: 50px;
                    display: inline-block;
                    vertical-align: top;
                  }
                  .comment {
                    width: 60%;
                    display: inline-block;
                    white-space: normal;
                    text-align: justify;
                  }

                  .text,
                  .score,
                  .comment {
                    padding-top: 3px;
                  }
                }

                .details_cordial {
                  background-color: #f3f3f3;
                  padding: 10px 20px;
                  border-radius: 25px;

                  img {
                    max-width: 150px;
                  }

                  &.sms {
                    text-align: start;
                  }

                  table {
                    margin-top: 15px;
                    margin-bottom: 0px;
                    height: 100%;
                    width: 100%;
                    table-layout: fixed;

                    th {
                      color: darkslategray;
                      text-align: center;
                    }

                    th:nth-child(2) {
                      width: 40%;
                      text-align: start;
                    }

                    td {
                      vertical-align: top;
                      text-align: center;
                    }

                    td:nth-child(2) {
                      width: 40%;
                      text-align: start;
                      //overflow: hidden;
                      //text-overflow: ellipsis;
                      //white-space: nowrap;
                    }
                  }

                  table.inStoreProperties {
                    th:nth-child(2) {
                      text-align: center !important;
                    }

                    td:nth-child(2) {
                      text-align: center !important;
                    }
                  }
                }

                .details_googleReview {
                  white-space: nowrap;

                  span {
                    display: inline-block;
                    vertical-align: top;
                    white-space: normal;
                    text-align: justify;
                    max-width: 80%;
                  }
                  span:first-child {
                    width: 50px;
                  }

                  svg {
                    width: 15px;
                    margin-top: -4px;
                  }

                  .response {
                    // white-space: normal;
                    // text-align: justify;
                    // max-width: calc(80% + 50px);
                    margin-top: 15px;
                  }
                }

                .details_callCenter {
                  span {
                    display: inline-block;
                    width: 120px;
                  }

                  span:nth-child(3) {
                    width: 200px;
                  }
                }

                .details_background {
                  background-color: #f3f3f3;
                  padding: 10px 20px;
                  border-radius: 25px;

                  &.order-notes-flag {
                    display: flex;
                    flex-direction: row;

                    > div {
                      width: 85%;
                    }

                    > button {
                      margin-left: auto;
                      height: 30px;
                      min-width: auto;
                      font-size: inherit;
                      margin-top: 0px;
                      padding: 0px 10px;
                      align-self: center;
                    }
                  }
                }

                .details_dispatchTrack {
                  margin-top: 10px;

                  .time {
                    background-color: transparent;
                    color: gray;
                    font-size: small;
                    margin-top: 0;
                    padding: 0;
                    text-align: right;
                    // float: right;
                  }
                }

                .details_podium {
                  max-width: 90%;

                  .agent {
                    background-color: #4c76e0;
                    width: fit-content;
                    padding: 15px;
                    border-radius: 25px;
                    border-bottom-right-radius: 0;
                    color: white;
                    margin-left: auto;
                    margin-right: 0;
                    margin-top: 20px;
                    max-width: 90%;

                    // float: right;
                  }

                  .customer {
                    background-color: #f4f6f9;
                    width: fit-content;
                    padding: 15px;
                    border-radius: 25px;
                    border-bottom-left-radius: 0;
                    margin-top: 20px;
                    max-width: 90%;
                  }

                  .time {
                    background-color: transparent;
                    color: gray;
                    font-size: small;
                    margin-top: 0;
                    padding: 0;
                  }

                  .separator {
                    display: flex;
                    align-items: center;
                    text-align: center;
                    color: grey;
                  }

                  .separator::before,
                  .separator::after {
                    content: '';
                    flex: 1;
                    border-bottom: 1px solid grey;
                  }

                  .separator:not(:empty)::before {
                    margin-right: 0.25em;
                  }

                  .separator:not(:empty)::after {
                    margin-left: 0.25em;
                  }
                }
              }

              .flag-details {
                display: flex;
                flex-direction: row;

                span {
                  margin-left: auto;
                }
              }
            }
          }
        }
      }

      .next .title {
        width: 59%;
        display: inline-block;
        padding: 0 5px;
        margin-bottom: 15px;
      }
      /* .month .title{width: 40%; display: inline-block;  padding: 0 5px;}*/

      .btnShowConversation {
        border: 1px solid;
        border-radius: 25px;
        outline: none;
      }
    }

    &.ext {
      .content {
        height: 100% !important;
      }
    }

    .filter {
      margin: auto;
      display: flex;

      &.contact-timeline {
        @media only screen and (max-width: 1235px) {
          height: 135px;

          .filter-checkbox {
            flex-direction: column;
          }
        }

        @media only screen and (min-width: 1236px) {
          height: 110px;

          .filter-checkbox {
            & > * {
              margin-left: 20px;
              &:first-child {
                margin-left: 0px;
              }
              input[type='checkbox'] {
                margin-top: 0;
              }
            }
          }
        }
      }

      // @media only screen and (min-width: 1547px) {
      //   height: 70px;
      // }

      .parent-div-filter {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        @media only screen and(min-width: 1660px) {
          width: 53%;
        }

        > div {
          margin: 0px 0px 0px 15px;
        }

        .parent-select {
          border-radius: 0.25rem;
          border: 1px solid #ced4da;
        }

        select {
          height: 36px;
          min-width: 150px;
          appearance: auto;
          -webkit-appearance: auto;
          padding-right: 10px;
          border-right: 5px solid transparent;
          border-color: transparent;

          &:hover {
            cursor: pointer;
          }
        }

        input[type='checkbox'] {
          padding: 10px;
          margin-left: 5px;
        }
      }

      .parent-div-filter-expand-btn {
        align-self: flex-end;
        margin-left: auto;
        margin-right: 15px;
        margin-bottom: -2px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        @media only screen and (max-width: 1235px) {
          margin-bottom: 35px;
        }

        @media only screen and (min-width: 1236px) and (max-width: 1499px) {
          margin-bottom: 10px;
        }

        @media only screen and (min-width: 1500px) and(max-width: 1546px) {
          margin-bottom: -2px;
        }

        @media only screen and (min-width: 1630px) {
          width: 47%;
          margin-bottom: 42px;
        }

        @media only screen and (min-width: 0px) and (max-width: 1434px) {
          width: 200px;
        }

        button {
          min-height: 38px;
          width: 185px;
        }

        .keyword-search {
          margin-left: 5px;
          width: 185px;

          @media only screen and (max-width: 1630px) {
            margin-top: 5px;
          }

          label {
            color: black;
            margin-top: -9px;

            &.MuiInputLabel-shrink {
              transform: translate(14px, -7px) scale(0.75);
            }
          }

          input::placeholder {
            opacity: 1;
            color: #212529;
          }

          .MuiFormLabel-root.Mui-focused {
            color: black;
          }
          .MuiOutlinedInput-root {
            height: 34px;

            input {
              padding: 0px 10px;
              margin-top: 5px;
            }

            fieldset {
              border: 1px solid #ced4da;
              color: #212529;
              top: 0px;
              height: 38px;
            }
          }
        }
      }

      label {
        margin-top: 5px;
        select {
          max-width: 180px;
          appearance: auto;
        }
      }

      label:nth-child(2) select {
        max-width: 240px;
      }

      .multi-check {
        margin: 5px 0px 0px 15px;
        button {
          min-width: 150px;
          max-width: 150px;
          width: 100%;
          padding: 0.375rem 0.75rem;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          color: #212529;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #ced4da;
          -webkit-appearance: none;
          appearance: none;
          border-radius: 0.25rem;
          transition:
            border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
          box-shadow: none;

          svg {
            margin-right: -15px !important;
          }
        }

        .css-1pcexqc-container {
          position: relative;
          box-sizing: border-box;
          border: 1px solid #dee2e6;

          .css-ik6y5r {
            > div {
              padding-bottom: 4px;
              padding-top: 4px;

              [class$='option'] {
                padding: 8px 12px;
              }
            }
          }
        }
      }

      .filter-checkbox {
        display: flex;
      }
    }

    .octFilter {
      label {
        margin: 5px 30px 0 15px;
        select {
          min-width: 180px;
          max-width: 240px;
        }
      }
    }
  }

  .MuiTabs-root {
    // background-color: white;
    // border-bottom: 3px solid #d5d5d5;
    background-color: #eee;
    opacity: 0.7;
    padding: 0px;
    height: 30px !important;
    min-height: auto !important;

    button {
      padding: 0px 10px 0px 10px;
      height: 30px !important;
      min-height: auto !important;

      &:focus {
        outline: none !important;
      }

      &.selected {
        background-color: white;
        color: blue;
        border-left: 3px solid #e6e6e6;
        border-right: 3px solid #e6e6e6;
      }

      &:first-child {
        &.selected {
          border-left: none;
        }
      }
    }
  }

  .accordion__heading {
    display: inline-block !important;
  }

  .header {
    height: revert !important;
    font-weight: bold;
    @media only screen and (max-width: 1500px) {
      font-size: 1rem;
    }

    @media only screen and (min-width: 1500px) {
      font-size: 1.2rem;
    }

    @media only screen and (min-width: 2000px) {
      font-size: 1.6rem;
    }
    // font-size: medium;
    display: inline-block;
  }

  .accordion__button {
    padding: 5px 15px;
    background-color: transparent;
  }
  .accordion__panel {
    padding: 5px;
    background-color: white;
  }

  .noDetails .accordion__button::before {
    color: transparent !important;
  }

  .accordion__button::before {
    color: #d4281c;
    height: 15px;
    width: 15px;
    border-right-width: 3px;
    border-bottom-width: 3px;
  }

  .accordion {
    border-style: none;
  }

  .hide {
    display: none;
  }

  .btnClose {
    right: 10px;
    top: 10px;
    position: absolute;
    border: 3px solid #d4281c;
    border-radius: 50px;
    padding: 0;
    // padding: 5px;
    background-color: transparent;
    outline: none !important;
    width: 40px;
    height: 40px;

    @media only screen and (min-width: 1800px) {
      right: 15px;
      top: 15px;

      svg {
        margin-top: -2px;
      }
    }

    @media only screen and (min-width: 2000px) {
      //right:25px;
      //top:25px;
      width: 50px;
      height: 50px;
      svg {
        width: 25px;
        height: 25px;
      }
    }
    // font-size: 32px;
    // font-size: 32px;
    // max-width: 100px;
    // font-size: large ;
  }
  .unsaveComment {
    background-color: #d4281c;

    &.form {
      position: relative;
    }
    input,
    textarea {
      background-color: transparent;
    }

    &::after {
      content: ' Unsaved comment';
      color: red;
      height: 30px;
      width: 100%;
      position: absolute;
      top: 52px;
    }
  }
}

//PODIUM MODAL -- UNSED
.podiumModal {
  .modal-content {
    position: fixed;
    right: calc(40px + 50px);
    top: 50px;
    height: 75vh;
    width: calc(65vw - 100px);

    .modal-body {
      overflow-y: scroll;
    }

    .agent {
      background-color: #4c76e0;
      width: fit-content;
      padding: 15px;
      border-radius: 25px;
      border-bottom-right-radius: 0;
      color: white;
      margin-left: auto;
      margin-right: 0;
      margin-top: 20px;
      max-width: 90%;

      // float: right;
    }

    .customer {
      background-color: #f4f6f9;
      width: fit-content;
      padding: 15px;
      border-radius: 25px;
      border-bottom-left-radius: 0;
      margin-top: 20px;
      max-width: 90%;
    }

    .time {
      background-color: transparent;
      color: gray;
      font-size: small;
      margin-top: 0;
      padding: 0;
    }

    .btnClose {
      right: 15px;
      top: 15px;
      position: absolute;
      border: 2px solid #d4281c;
      border-radius: 20px;
      background-color: transparent;
      outline: none !important;
      width: 40px;
      height: 40px;

      svg {
        margin-bottom: 2px;
      }
    }
  }
}

//modal
.order-reminder-modal {
  .btnClose {
    right: 10px;
    top: 10px;
    position: absolute;
    border: 3px solid #d4281c;
    border-radius: 50px;
    padding: 0;
    // padding: 5px;
    background-color: transparent;
    outline: none !important;
    width: 40px;
    height: 40px;

    opacity: 1;
    // padding: 1rem;
    margin: 0;

    svg {
      position: absolute;
      top: 8px;
      right: 7px;
    }
  }

  .iss-button-red {
    position: relative;
  }

  .view-textarea {
    width: 100%;
    height: 100%;
    border: 0;
    margin: 0;
    display: inline-flex;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
  }

  .textarea-show {
    height: 200px;
    width: auto;
  }
}

.reminder-order {
  text-align: center;

  div {
    margin-bottom: 20px;
  }

  .shortchut {
    span {
      display: inline-block;
      width: calc(33% - 20px);
      height: 30px;
      margin: 0px 10px 0px 10px;
      padding-top: 3px;
      border-radius: 5px;
      background-color: #e0e0e0;
      cursor: pointer;

      &.dateselected {
        font-weight: bold;
      }
    }
  }
}

#keyword-search-count {
  background-color: white;
  min-width: 120px;
  height: 30px;
  position: absolute;
  border-radius: 5px;
  border: 1px solid #ced4da;
  text-align: start;
  padding: 0px 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: 0px 1px 3px 0px #ced4da;
  z-index: 1051;
  top: 8px !important;

  // @media only screen and (max-width: 1499px) {
  //   top: 358px;
  // }
  // @media only screen and (min-width: 1500px) and (max-width: 1546px) {
  //   top: 362px;
  // }
  // @media only screen and (min-width: 1547px) and (max-width: 1599px) {
  //   top: 338px;
  // }
  // @media only screen and (min-width: 1600px) and (max-width: 1999px) {
  //   top: 349px;
  // }
  // @media only screen and (min-width: 2000px) and (max-width: 2047px) {
  //   top: 369px;
  // }
  // @media only screen and (min-width: 2048px) {
  //   top: 379px;
  // }
  svg {
    cursor: pointer;
    user-select: none;
    height: 20px;
    width: 20px;
  }
}

.cust360ContactTimeline-modal {
  .cust360ContactTimeline-modal-body {
    .image-container {
      width: 100%;
      // max-height: 80vh; /* Set a max height for larger screens */
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: auto; /* Crop if necessary */
    }

    /* Make the image fit nicely within the container */
    .modal-image {
      width: 100%;
      height: auto;
      max-height: 100%;
      object-fit: contain; /* Keeps the aspect ratio and fits within the container */
    }
  }
}
