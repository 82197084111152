.loading {
  text-align: center;

  .spinner-border {
    vertical-align: middle !important;
  }
}

.typeFilter {
  label {
    margin: 5px 30px 0 15px;
  }

  select {
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
  }
}

.accordianOrderTimeline {
  .accordion__item + .accordion__item {
    border-top: none;
    padding-top: 10px;
  }
}

.accordianHeader {
  .accordion__button {
    padding: 5px 15px;
    background-color: transparent;
  }

  img {
    width: 40px;
    display: inline-block;
  }

  .title {
    padding-right: 20px;
    display: inline-block;

    .orderTitle {
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
    }

    .highlightTitle {
      color: blue;
      display: inline-block;
    }
  }
}

.tableDetails {
  // word-break: break-word;
  margin-left: 80px;
  word-break: break-word;
  background: transparent;
  background-color: #f3f3f3;
  padding: 10px 20px;
  border-radius: 25px;
  width: -webkit-fill-available;

  th:first-child,
  td:first-child {
    padding-left: 20px;
  }

  th[scope] {
    width: 300px;
  }

  th {
    color: gray;
  }

  td,
  th {
    min-height: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
