@font-face {
  font-family: 'ARS Maquette Pro';
  src: url('ARSMaquettePro-BlackItalic.eot');
  src:
    local('ARS Maquette Pro Black Italic'),
    local('ARSMaquettePro-BlackItalic'),
    url('ARSMaquettePro-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('ARSMaquettePro-BlackItalic.woff2') format('woff2'),
    url('ARSMaquettePro-BlackItalic.woff') format('woff'),
    url('ARSMaquettePro-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'ARS Maquette Pro';
  src: url('ARSMaquettePro-Regular.eot');
  src:
    local('ARS Maquette Pro Regular'),
    local('ARSMaquettePro-Regular'),
    url('ARSMaquettePro-Regular.eot?#iefix') format('embedded-opentype'),
    url('ARSMaquettePro-Regular.woff2') format('woff2'),
    url('ARSMaquettePro-Regular.woff') format('woff'),
    url('ARSMaquettePro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ARS Maquette Pro';
  src: url('ARSMaquettePro-LightItalic.eot');
  src:
    local('ARS Maquette Pro Light Italic'),
    local('ARSMaquettePro-LightItalic'),
    url('ARSMaquettePro-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('ARSMaquettePro-LightItalic.woff2') format('woff2'),
    url('ARSMaquettePro-LightItalic.woff') format('woff'),
    url('ARSMaquettePro-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'ARS Maquette Pro';
  src: url('ARSMaquettePro-Bold.eot');
  src:
    local('ARS Maquette Pro Bold'),
    local('ARSMaquettePro-Bold'),
    url('ARSMaquettePro-Bold.eot?#iefix') format('embedded-opentype'),
    url('ARSMaquettePro-Bold.woff2') format('woff2'),
    url('ARSMaquettePro-Bold.woff') format('woff'),
    url('ARSMaquettePro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ARS Maquette Pro';
  src: url('ARSMaquettePro-Italic.eot');
  src:
    local('ARS Maquette Pro Italic'),
    local('ARSMaquettePro-Italic'),
    url('ARSMaquettePro-Italic.eot?#iefix') format('embedded-opentype'),
    url('ARSMaquettePro-Italic.woff2') format('woff2'),
    url('ARSMaquettePro-Italic.woff') format('woff'),
    url('ARSMaquettePro-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'ARS Maquette Pro';
  src: url('ARSMaquettePro-MediumItalic.eot');
  src:
    local('ARS Maquette Pro Medium Italic'),
    local('ARSMaquettePro-MediumItalic'),
    url('ARSMaquettePro-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('ARSMaquettePro-MediumItalic.woff2') format('woff2'),
    url('ARSMaquettePro-MediumItalic.woff') format('woff'),
    url('ARSMaquettePro-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'ARS Maquette Pro';
  src: url('ARSMaquettePro-Medium.eot');
  src:
    local('ARS Maquette Pro Medium'),
    local('ARSMaquettePro-Medium'),
    url('ARSMaquettePro-Medium.eot?#iefix') format('embedded-opentype'),
    url('ARSMaquettePro-Medium.woff2') format('woff2'),
    url('ARSMaquettePro-Medium.woff') format('woff'),
    url('ARSMaquettePro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'ARS Maquette Pro';
  src: url('ARSMaquettePro-Light.eot');
  src:
    local('ARS Maquette Pro Light'),
    local('ARSMaquettePro-Light'),
    url('ARSMaquettePro-Light.eot?#iefix') format('embedded-opentype'),
    url('ARSMaquettePro-Light.woff2') format('woff2'),
    url('ARSMaquettePro-Light.woff') format('woff'),
    url('ARSMaquettePro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'ARS Maquette Pro';
  src: url('ARSMaquettePro-BoldItalic.eot');
  src:
    local('ARS Maquette Pro Bold Italic'),
    local('ARSMaquettePro-BoldItalic'),
    url('ARSMaquettePro-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('ARSMaquettePro-BoldItalic.woff2') format('woff2'),
    url('ARSMaquettePro-BoldItalic.woff') format('woff'),
    url('ARSMaquettePro-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'ARS Maquette Pro';
  src: url('ARSMaquettePro-Black.eot');
  src:
    local('ARS Maquette Pro Black'),
    local('ARSMaquettePro-Black'),
    url('ARSMaquettePro-Black.eot?#iefix') format('embedded-opentype'),
    url('ARSMaquettePro-Black.woff2') format('woff2'),
    url('ARSMaquettePro-Black.woff') format('woff'),
    url('ARSMaquettePro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
