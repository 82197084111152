.cart-wishlist .styled-table {
  width: 100%;
  border-collapse: collapse;
}

.cart-wishlist .styled-table th,
.cart-wishlist .styled-table td {
  padding: 12px 15px;
  text-align: left;
  font-weight: bold; /* Make all text bold */
}

.cart-wishlist .styled-table th {
  background-color: #f4f4f4;
  border-bottom: 2px solid #000; /* Adding underline to header */
}

.cart-wishlist .styled-table td {
  border-bottom: 1px solid #dddddd;
}

.cart-wishlist .styled-table .image {
  width: 150px; /* Increase the width for image td */
}

.cart-wishlist .styled-table .image img {
  width: 100%;
  height: auto;
  display: block;
}

.cart-wishlist .carts-display {
  margin-top: 20px;
}

.cart-wishlist .wishlist-display {
  margin-top: 20px;
}

.cart-wishlist .no-data {
  text-align: center;
  padding: 20px;
  font-weight: bold;
  color: #888;
}
