// Import common chatbot styles
@import '../chatbot.scss';

// Product Chatbot Specific Styles

// Product chatbot settings
.chatbot-setting {
  width: 500px;
  z-index: 10000 !important;

  .MuiBox-root {
    height: 350px;
    overflow: unset;

    .update-setting-button {
      float: right;
      margin-bottom: 1rem;
    }

    .zip-code-setting > .MuiInputBase-root {
      padding-right: 8px;
    }

    .dropdown-select {
      width: 100%;
      z-index: 10001 !important;
      margin: 0 0 8px 0;

      [class$='control'],
      [class$='option'] {
        cursor: pointer;
      }

      [class$='menu'] {
        z-index: 5001;
        position: relative;
        width: auto;
      }
    }
  }
}

// Chat prompt styles
.chat-prompt-container {
  padding: 16px;
  background-color: #f8f9fa;
  border-radius: 8px;
  margin-bottom: 16px;
  overflow: auto;
  height: 100%;

  &.full-screen {
    height: 100%;
    transition: height 0.3s ease-in-out;
  }

  .prompt-step {
    margin-bottom: 20px;

    .step-title {
      color: #555;
      margin-bottom: 12px;
      font-weight: 500;
      font-size: 0.95rem;
    }

    .options-container {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 8px;

      .option-button {
        border-radius: 20px;
        text-transform: none;
        padding: 6px 16px;
        min-width: auto;
        font-size: 0.9rem;
        border: 1px solid rgba(208, 40, 27, 0.5);
        color: #d0281b;
        background-color: transparent;
        transition: all 0.2s ease-in-out;

        &:hover {
          background-color: rgba(208, 40, 27, 0.04);
          border-color: #d0281b;
        }

        &.selected {
          background-color: #d0281b;
          color: white;
          border-color: #d0281b;

          &:hover {
            background-color: #b02217;
          }
        }
      }
    }
  }

  .prompt-footer {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-top: 24px;

    .product-search {
      flex: 1;
      max-width: 300px;

      &.MuiAutocomplete-root {
        width: 100%;

        .MuiOutlinedInput-root {
          padding-right: 40px !important; // Space for the loading indicator

          &.Mui-focused {
            .MuiOutlinedInput-notchedOutline {
              border-color: #d0281b;
            }
          }
        }

        .MuiAutocomplete-endAdornment {
          right: 8px;

          .MuiCircularProgress-root {
            color: #d0281b;
          }
        }
      }

      .MuiOutlinedInput-root {
        &:hover .MuiOutlinedInput-notchedOutline {
          border-color: rgba(208, 40, 27, 0.7);
        }
      }

      .MuiFormLabel-root {
        &.Mui-focused {
          color: #d0281b;
        }
      }

      .MuiAutocomplete-option {
        &[aria-selected='true'] {
          background-color: rgba(208, 40, 27, 0.08) !important;
        }

        &.Mui-focused {
          background-color: rgba(208, 40, 27, 0.04) !important;
        }
      }
    }

    .button-red {
      margin-top: 0; // Override the default margin from button-red
    }
  }
}
