@import '../../styles/scaling';

.cr-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: var(--sidebar-collapsed-width);
  height: var(--viewport-height);
  background-color: #ffffff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  z-index: 99;

  // Add all transition properties
  transition: all 0.3s ease-in-out;
  will-change: width;

  + .cr-header,
  + .cr-content {
    transition: all 0.3s ease-in-out;
    will-change: margin-left, width;
  }

  &--open {
    width: var(--sidebar-width);

    + .cr-header,
    + .cr-content {
      margin-left: var(--sidebar-width);
    }
  }

  background-position: center center;
  background-size: cover;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  font-weight: 200;

  &::after,
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    width: 100%;
    height: 100%;
    content: '';
  }

  &::before {
    opacity: 0.33;
  }

  &::after {
    z-index: 3;
    // sublime light
    background: #fff; /* fallback for old browsers */
    opacity: 1;
  }

  &[data-image]::after {
    opacity: 0.77;
  }

  &__content {
    height: var(--viewport-height);
    position: relative;
    z-index: 4;
    // padding-bottom: 30px;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    ul {
      margin-top: 0px !important;
    }

    /*li:hover { background-color: red;}*/
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
    background-color: #000;
  }

  &__nav-item-collapse {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  &__nav-item-icon {
    // @include media-breakpoint-down(md) {
    //   padding: 0;
    //   margin: 0 auto;
    // }
    width: 32px;
    height: 32px;
    margin-right: 6px;

    &.menu-pipeline {
      opacity: 0.9;
      transform: scale(0.8);
    }
    &.menu-products {
      opacity: 0.9;
      transform: scale(0.9);
    }
  }

  .nav {
    margin-top: 10px;
    height: var(--viewport-height);

    .nav-item {
      margin: 0px;
      /*color: theme-color(light);*/
      color: #343434;
      opacity: 1;
      position: relative;
      font-size: 0.9rem;

      &.folder span::before {
        content: ' ';
        display: inline-block;
        z-index: -1;
        width: 36px;
        background-color: #a0a0a0;
        height: 2px;
        position: absolute;
        left: 13px;
        bottom: 7px;
      }

      &.folder span::after {
        content: ' ';
        display: inline-block;
        z-index: -1;
        width: 190px;
        background-color: #a0a0a0;
        height: 2px;
        position: absolute;
        left: 60px;
        bottom: 7px;
      }

      &.folder a {
        font-weight: bold;
        font-size: 0.9rem;
      }
    }

    .nav-link {
      // @include media-breakpoint-down(md) {
      //   padding: .5rem;
      // }
      color: #343434;
      padding: 4px 0px 4px 13px !important;
      height: 53px;
      cursor: pointer;

      &.active {
        color: #d0281b;
        background: rgba(#c13a2a, 0.1);
        border-left: 4px solid #d0281b;
        padding-left: 9px !important;

        .order-alert svg {
          color: #d0281b;
        }
      }

      span {
        padding-left: 6px;
        white-space: nowrap;
        color: #000;
        display: inline-block;
      }

      .opportunity-circle {
        border: 1px solid black;
        height: 35px;
        width: 35px;
        border-radius: 50%;
        color: black;
        background-color: white;
        text-align: center;
        margin-right: 6px;
        line-height: 2;
        font-size: medium;

        &.green-badge {
          background-color: green !important;
          color: white;
        }
        &.yellow-badge {
          background-color: yellow !important;
          color: white;
        }
        &.red-badge {
          background-color: red !important;
          color: white;
        }
      }

      //Not using currently
      // .opportunity-icon-badge {
      //   padding: 0px;

      //   .MuiBadge-badge {
      //     background-color: white;
      //     border: 1px solid #343434;
      //     padding: inherit;
      //     text-align: center;
      //     line-height: 1.5;

      //     &.green-badge {
      //       background-color: green !important;
      //       color: white;
      //       border: none;
      //     }
      //     &.yellow-badge {
      //       background-color: yellow !important;
      //       color: white;
      //       border: none;
      //     }
      //     &.red-badge {
      //       background-color: red !important;
      //       color: white;
      //       border: none;
      //     }
      //   }
      // }

      .align-self-start {
        padding-top: 5px;
      }

      .order-alert {
        position: absolute;
        width: 45px;
        height: 45px;
        z-index: 99;
      }
      .order-alert svg {
        position: absolute;
        color: #343434;
        right: 0px;
        top: 7px;
        width: 18px;
        height: 18px;
      }
    }
    .version {
      width: 100%;
      margin-top: auto;
      text-align: center;
      font-size: 0.6rem;
      color: #606060;
      z-index: -1;
    }
  }

  .iss-header-menu-toggle {
    height: 60px;

    :is(svg, .pngIcon) {
      cursor: pointer;
      margin: 5px 5px 0px 13px;
      color: #343434;
    }
  }
}

.opportunity-list-drawer {
  .MuiPaper-root {
    width: 50% !important;
  }
}

// @include gradient-y(#02aab0, #00cdac); // green beach
// @include gradient-y(#348f50, #56b4d3); // emerald water
// @include gradient-y(#4CB8C4, #3CD3AD); // sea weed

// stripe
// background: #1fa2ff; /* fallback for old browsers */
// background: linear-gradient(
//   to bottom,
//   #1fa2ff,
//   #12d8fa,
//   #a6ffcb
// );

// subu
// background: #0cebeb; /* fallback for old browsers */
// background: -webkit-linear-gradient(
//   to bottom,
//   #29ffc6,
//   #20e3b2,
//   #0cebeb
// ); /* Chrome 10-25, Safari 5.1-6 */
// background: linear-gradient(
//   to bottom,
//   #29ffc6,
//   #20e3b2,
//   #0cebeb
// ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

// mojito
// background: #1d976c; /* fallback for old browsers */
// background: -webkit-linear-gradient(
//   to bottom,
//   #93f9b9,
//   #1d976c
// ); /* Chrome 10-25, Safari 5.1-6 */
// background: linear-gradient(
//   to bottom,
//   #93f9b9,
//   #1d976c
// ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
