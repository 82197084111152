//General CSS/Override default CSS
.btn,
.btn:active,
.btn:focus {
  box-shadow: none !important;
  outline: none !important;
  transition: none !important;
  transition-property: none !important;
}

//Button Close on Modal
.modal-btn-close {
  border-radius: 50% !important;
  border: 2px solid #d0281b !important;
  color: black !important;
  background-color: transparent !important;
  user-select: none !important;
  width: 40px !important;
  height: 40px !important;
  float: right !important;
  position: absolute;
  right: 16px !important;
  margin-top: -5px;

  svg {
    margin-left: -3px;
    margin-top: -2px;
  }

  &:hover,
  &:active {
    cursor: pointer !important;
    background: #af2116 !important;
    background-color: #af2116 !important;
    border: 2px solid #af2116 !important;
    color: white !important;
  }
}

//Dropdown
.dropdown-select {
  display: inline-block;
  min-width: 228px;
  width: auto;
  margin-top: 16px;
  margin-left: 16px;
}

//Red Button
.button-red {
  display: inline-block;
  margin-top: 10px;
  padding: 2px 10px;
  border: 1px solid #d0281b !important;
  user-select: none;
  height: 42px;
  min-width: 190px;
  border-radius: 5px;
  margin-right: 2px;
  background-color: transparent !important;
  color: black !important;

  &.default {
    background-color: #d0281b !important;
    color: white !important;
    font-weight: bold;
  }

  &.grey {
    background-color: #a19b9b !important;
    color: white !important;
    font-weight: bold;
  }

  &.white {
    background-color: white !important;
    color: black !important;
    font-weight: 500;
  }
}

.button-red:hover,
.button-red:active {
  cursor: pointer;
  background: #af2116;
  background-color: #af2116 !important;
  border: 1px solid #af2116 !important;
  color: white !important;
}

//Autocomplete
.custom-common-autocomplete {
  min-width: 228px;

  .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 0;
    height: 45px;
  }

  fieldset {
    height: 100%;
    margin-top: 5px;
    border: 1px solid #d7dae2 !important;
  }
}

.MuiAutocomplete-popper {
  //For selected value
  .MuiAutocomplete-option[aria-selected='true'] {
    background: #ff7285 !important;
    color: white;
  }

  //On focus hover
  ul > li:hover,
  ul > li:active,
  .MuiAutocomplete-option[data-focus='true'] {
    background: #f9ebe9;
  }
}
