.twomindrill-modal {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: fit-content;
  margin-top: 2%;

  .MuiBox-root {
    width: 60% !important;
    height: auto !important;
    border: 1px solid black;
    border-radius: 5px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 1440px) {
      width: 80% !important;
    }

    .modal-body {
      text-align: center;
      margin-top: 20px;
      width: 100%;

      .checkboxes {
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
      }

      .twomin-category-wrapper {
        display: flex;
        margin-bottom: 20px;
      }

      .category-section,
      .details-section,
      .newHire-section {
        flex: 1;
      }

      @media (max-width: 1440px) {
        .category-section {
          flex: 1;
        }

        .details-section {
          flex: 1.1;
        }
      }

      .expandable-icon {
        display: flex;
        margin-top: -15px;
      }

      .twomin-boxes {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
      }

      .twomin-box {
        width: 48%;
        height: 50px;
        padding: 10px;
        background-color: white;
        border: 1px solid #ff2c2c;
        border-radius: 4px;
        text-align: center;
        cursor: pointer;
        margin: 5px 0;
        @media (max-width: 1440px) {
          padding: 0px !important;
          margin: 0px 0 !important;
          height: 40px;
        }
      }

      .twomin-box.selected {
        background-color: #d0281b;
        color: white;
      }

      .divider {
        width: 1px;
        background-color: #ccc;
        margin: 0 20px;
      }

      .details-inputs {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .textarea-2minutes {
          font-style: italic;
          padding-left: 5px;
          padding-right: 15px;
          margin-top: 10px;
          font-family: ARSMaquettePro;
          font-size: 1rem;
          color: #000000;
          min-height: 8rem;
          width: 100%;
          border: 1px solid #e0e0e0;
          outline: none;
          resize: none;

          @media (max-width: 1440px) {
            min-height: 3rem !important;
          }
        }
      }

      .section-header {
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      .section-title {
        margin-left: 8px;
        font-weight: bold;
      }

      .required-field::after {
        content: '*';
        color: red;
        margin-left: 5px;
      }
      .action-buttons {
        display: flex; /* Makes buttons align horizontally */
        gap: 10px; /* Adds spacing between the buttons */
        justify-content: center; /* Aligns buttons to the right (optional) */
        .close-button {
          background-color: white !important;
          color: black !important;
          font-weight: 500;
          margin-left: 0; /* Reset margin override */
        }
      }

      /* Parent Container */
      .twomin-textarea {
        width: 100%; /* Ensures parent takes full width */
        max-width: 100%; /* Allow it to stretch within the modal */
      }

      /* Textarea */
      .checkin-textarea {
        width: 100%; /* Make textarea take full width of its parent */
        min-height: 120px; /* Adjust height as needed */
        box-sizing: border-box; /* Includes padding/border in width calculation */
        padding: 10px;
        font-size: 14px;
        font-style: italic;
        border: 1px solid #141414;
        border-radius: 4px;
        resize: none; /* Prevent textarea resizing */
      }
    }
  }
}

.opportunity-list {
  height: 100%;
  padding: 0 10px;

  .iss-header {
    height: 40px;
    width: 100%;
    background-color: white;
    margin: 10px 0px;

    .iss-logo {
      height: inherit;
    }

    .iss-header-separator {
      height: auto;
      border-right: 2px solid #666666;
      margin-right: 15px;
      margin-left: 15px;
    }

    .parent-tabs {
      width: 100%;
      height: 100%;
      flex-direction: row;

      .page-title {
        font-weight: bolder;
        font-size: larger;
        color: #666666;
      }

      .connection {
        margin-left: 10px;
        color: #d0281b;

        &.connected {
          color: green;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .right-float {
      margin-left: auto;
      position: absolute;
      right: 0;

      svg {
        cursor: pointer;
        margin-left: 10px;
        color: #d0281b;

        &:hover {
          color: white;
          background-color: #d0281b;
          border-radius: 5px;
          padding: 5px;
        }
      }
    }
  }

  //Dashboard
  .opportunity-dashboard {
    display: flex;
    flex-direction: column;
    height: calc(100vh / var(--scale-factor) - 100px);

    .opportunity-question-filter {
      margin-bottom: -20px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .opportunity-question-filter label {
      font-size: 16px;
      margin-top: 14px;
    }

    .MuiGrid-root.MuiGrid-container {
      max-height: calc(100% - 60px);
      // min-height: calc(100% - 60px);
      justify-content: start;
      flex-wrap: nowrap;

      .MuiGrid-root.MuiGrid-item {
        width: 100%;
        padding-top: 0px;
        margin-top: 16px;

        .matrix-box {
          border: 1px solid #bcbcbc;
          border-radius: 10px;
          min-height: 150px;
          padding: 15px;

          > div {
            font-weight: bold;
            text-align: center;
            min-height: 50px;

            &:not(:nth-child(1)) {
              font-size: 1.5rem;

              &::before {
                border-bottom: 1px solid #bcbcbc;
                display: block;
                content: '';
                margin: 10px 0 10px 0;
              }
            }
          }
        }
      }
    }

    .dashboard-data-grid {
      margin-top: 2rem;
      height: 100%;

      .MuiTablePagination-displayedRows {
        margin: 0;
      }

      .MuiDataGrid-columnHeaders {
        min-height: 70px !important;
        max-height: 70px !important;

        .MuiDataGrid-columnHeaderTitle {
          white-space: normal;
          word-wrap: break-word;
          line-height: 1.5;
        }
      }
    }
  }

  .dashboard-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 2rem;
    height: 100%;

    thead {
      background-color: #f5f5f5;
      th {
        padding: 15px;
        text-align: center;
        font-weight: bold;
        border-bottom: 1px solid #bcbcbc;
        font-size: 1rem;
      }
    }

    tbody {
      tr {
        &:nth-child(even) {
          background-color: #f9f9f9;
        }
        &:nth-child(odd) {
          background-color: #ffffff;
        }
      }

      td {
        padding: 10px;
        text-align: center;
        border-bottom: 1px solid #bcbcbc;
        font-size: 1rem;
      }
    }
  }

  //Floor
  .grid {
    &.MuiGrid-root.MuiGrid-container {
      max-height: calc(100% - 60px);
      min-height: calc(100% - 60px);
      justify-content: start;
      flex-wrap: nowrap;
    }

    .MuiGrid-root.MuiGrid-item {
      width: 100%;
      padding-top: 0px;
      margin-top: 16px;
    }

    .grid-header {
      background-color: #d0281b;
      color: white;
      padding: 10px 0px;
      font-weight: bold;
      justify-content: center;
      display: flex;
      flex-direction: row;
      border-radius: 5px 5px 0px 0px;
    }

    .grid-floor {
      border: 1px solid #d0281b;
      padding-top: 10px;
      display: inline-block;
      overflow: auto;
      overflow-y: scroll;
      width: 100%;
      height: 430px;

      &.queue {
        .MuiBox-root {
          width: 100%;
          display: inline-block;
        }
      }

      .custom-grid {
        width: 100%;
        display: grid;
        grid-auto-flow: row dense;
        grid-template-columns: repeat(2, 1fr);
      }

      .MuiBox-root {
        height: fit-content;
        width: 100%;
        padding: 0px 10px;
        display: inline-block;
        margin-bottom: 10px;

        &.own {
          position: sticky;
          top: 0;
          bottom: 10px;
          background-color: white;
          z-index: 1;

          .floor-avatar {
            box-shadow: 0 0 5px 2px #d0281b;
            border: 1px solid #d0281b;
          }
        }

        .floor-avatar {
          display: flex;
          flex-direction: column;
          border: 1px solid #666666;
          border-radius: 5px;
          min-height: 70px;
          align-items: center;
          justify-content: center;
          padding: 0px 10px;

          &.shadeflags {
            background-color: #f1dbd9;
          }

          &.inactive,
          &.ghost {
            background-color: #dedede;
          }

          //For hover
          &:hover {
            background-color: #f9ebe9;
            cursor: pointer;

            .queue-counter {
              background-color: #d0281b;
              border: 1px solid #d0281b;
              color: white;
            }

            .floor-actionables {
              display: block !important;
            }
          }

          .queue-counter {
            border: 1px solid #666666;
            border-radius: 50%;
            margin-right: 20px;
            width: 25px;
            height: 25px;
            text-align: center;
          }

          .MuiAvatar-root {
            margin-right: 10px;
          }

          .floor-avatar-stack {
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: center;
            min-height: 65px;

            .floor-avatar-content {
              display: flex;
              flex-direction: column;

              > div {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 250px;
              }

              .small-details {
                // white-space: normal;
                font-size: small;
                color: #999;
              }

              &.manager-highlight {
                .name {
                  user-select: none;
                  text-decoration: underline;
                  color: #0d6efd;
                }

                &:hover {
                  .name {
                    font-weight: bold;
                  }
                }
              }
            }

            .floor-actionables {
              margin-left: auto;
              align-self: center;

              .more-buttons-manager {
                display: flex;
              }

              .MuiIconButton-root {
                margin-left: 5px;
                padding: 5px;
                border-radius: 50%;
                color: rgba(0, 0, 0, 0.54);

                &:hover {
                  background-color: #d0281b;
                  color: white;
                }
              }
            }
          }

          hr {
            width: 100%;
            margin: 7px;
          }
        }

        .user-info-popper {
          z-index: 1201;
          background-color: white;
          width: 350px;
          min-height: 150px;
          border: 1px solid #666666;
          border-radius: 5px;
          padding: 1rem;

          h6 {
            width: 270px;
            font-weight: bold;
            text-decoration: underline;
          }

          button.modal-btn-close {
            top: 10px;
          }

          > div:first-child {
            margin-bottom: 1rem;
          }
        }
      }

      .begin-opportunity-modal {
        width: 50%;
        height: 600px;
        position: fixed;
        top: 100px;
        left: 25%; //25% from width
        right: 25%; //25% from width

        .MuiBox-root {
          border: 1px solid black;
          border-radius: 5px;
          background-color: white;
          width: 100%;
          height: 100%;

          .modal-header {
            padding: 1rem;
            border-bottom: 1px solid #dee2e6;

            h5 {
              margin-bottom: 0;
              line-height: 1.5;
              font-family: 'ARS Maquette Pro' !important;
              font-weight: 400;
            }
          }

          .modal-body {
            padding: 1rem;

            .opportunity-options {
              width: 100%;
              display: grid;
              grid-auto-flow: row dense;
              grid-template-columns: 1fr 1fr 1fr;
              column-gap: 1.5rem;
              justify-content: center;
              margin-top: 20px;

              button {
                height: 100px;
                margin-bottom: 10px;

                &.option-walk-in {
                  height: 200px;
                  width: auto;
                  font-size: 2rem;
                  grid-area: 1 / 1 / 2 / 4;
                }

                &.option-return-guest {
                  grid-area: 2 / 1 / 3 / 2;
                  font-size: 1.2rem;
                }

                &.option-incoming-call {
                  grid-area: 2 / 2 / 3 / 3;
                  font-size: 1.2rem;
                }

                &.option-unattended-guest {
                  font-size: 1.2rem;
                  grid-area: 2 / 3 / 3 / 4;
                }

                &.option-cancel {
                  font-size: 1.25rem;
                  grid-area: 3 / 1 / 4 / 4;
                  color: red !important;
                  font-weight: bold;

                  i {
                    font-size: medium;
                  }

                  &:hover,
                  &:active {
                    font-size: 1.3rem;
                    background-color: transparent !important;
                  }
                }
              }
            }
          }
        }
      }

      .close-opportunity-modal {
        width: 50%;
        height: 650px;
        position: fixed;
        top: 100px;
        left: 25%; //25% from width
        right: 25%; //25% from width

        .MuiBox-root {
          border: 1px solid black;
          border-radius: 5px;
          background-color: white;
          width: 100%;
          height: auto;

          .modal-header {
            padding: 1rem;
            border-bottom: 1px solid #dee2e6;

            h5 {
              margin-bottom: 0;
              line-height: 1.5;
              font-family: 'ARS Maquette Pro' !important;
              font-weight: 400;
            }
          }

          .modal-body {
            padding: 1rem;

            .opportunity-options {
              width: 100%;
              display: grid;
              grid-auto-flow: row dense;
              grid-template-columns: 1fr 1fr 1fr;
              column-gap: 1.5rem;
              justify-content: center;
              margin-top: 20px;

              button {
                height: 100px;
                margin-bottom: 10px;
              }
            }
          }
        }
      }

      .unavailable-opportunity-modal {
        width: 50%;
        height: 550px;
        position: fixed;
        top: 100px;
        left: 25%; //25% from width
        right: 25%; //25% from width

        .MuiBox-root {
          border: 1px solid black;
          border-radius: 5px;
          background-color: white;
          width: 100%;
          height: 100%;

          .modal-header {
            padding: 1rem;
            border-bottom: 1px solid #dee2e6;

            h5 {
              margin-bottom: 0;
              line-height: 1.5;
              font-family: 'ARS Maquette Pro' !important;
              font-weight: 400;
            }
          }

          .modal-body {
            padding: 1rem;

            .opportunity-options {
              display: flex;
              flex-direction: column;
              justify-content: center;
              margin-top: 20px;

              button {
                margin-bottom: 10px;
              }
            }
          }
        }
      }

      .information-opportunity-modal {
        width: 50%;
        // height: 625px;
        position: fixed;
        top: 100px;
        left: 25%; //25% from width
        right: 25%; //25% from width
        display: flex;
        flex-direction: row;

        .modal-btn-close {
          margin-top: 5px;
        }

        .MuiBox-root {
          border: 1px solid black;
          border-radius: 5px;
          background-color: white;
          width: 100%;
          height: 625px;
          display: flex;
          flex-direction: column;

          button.modal-btn-close {
            margin-top: 5px;
          }

          .information-tabs > ul {
            margin-top: 10px;
          }

          .guest-info-tab-body {
            padding: 1rem;
            height: 100%;

            input {
              margin-top: 1rem;
              height: 45px !important;

              &:focus {
                box-shadow: none !important;
                border-color: #3f51b5 !important;
                border-width: 2px;
              }
            }

            .opp-modal-accordion {
              .accordion__item {
                .accordion__button {
                  display: inline-flex;
                  background-color: transparent;
                  padding: 0.5rem 0 0.5rem 0;

                  &::before {
                    color: #d0281b;
                    margin: 5px 1rem 0 0;
                  }
                }

                .accordion__panel {
                  padding: 0 1rem 0 1rem;
                }

                &.accordion__item + .accordion__item {
                  border-top: none;
                }
              }

              .MuiStack-root {
                margin-top: 1rem;

                .MuiListItem-root {
                  border: 1px solid #dee2e6;
                  justify-content: center;

                  &.selected {
                    background-color: #d0281b;
                    border: 1px solid #d0281b;
                    color: white;
                    font-weight: bold;
                  }

                  &:hover {
                    background-color: #d0281b;
                    border: 1px solid #d0281b;
                    color: white;
                    font-weight: bold;
                    cursor: pointer;
                  }
                }
              }

              .guest-info {
                .MuiGrid-container {
                  margin-top: 1px;

                  input {
                    margin-top: 0px;
                  }

                  #potential-cust-button {
                    margin-top: 0px;
                    margin-right: 0px;
                    width: 100%;
                    min-width: auto;
                  }
                }

                .guest-profile-link {
                  margin-top: 1rem;
                  display: inline-block;
                  color: blue;
                  text-decoration: underline;
                  cursor: pointer;

                  &:hover {
                    text-decoration: none;
                  }
                }

                .guest-consent {
                  margin: 1rem 0 0 0;
                  user-select: none;
                  display: block;
                }
              }
            }
          }

          .opportunity-info-tab-body {
            padding: 1rem;

            .button-red {
              height: 100px;
              width: 100%;
              font-size: 1.2rem;
            }
          }

          .notes-tab-body {
            padding: 1rem;

            textarea {
              width: 100%;
              padding: 1rem;
              display: block;
              border: 1px solid #ced4da;
              border-radius: 5px;
              margin: 1rem 0;

              &:focus-visible {
                outline: none;
              }
            }
          }

          .info-modal-footer {
            display: flex;
            margin-top: auto;
            margin-bottom: 1rem;
            margin-left: auto;
            text-align: end;
          }
        }

        .MuiBox-root.cart-wishlist {
          width: 100%;

          .MuiButtonGroup-root {
            align-self: center;
            margin-top: 1rem;
          }

          .cart-wishlist-body-display {
            height: 100%;
            overflow-y: scroll;
            overflow-x: hidden;
            padding: 1rem;

            .carts-display {
              div {
                padding: 10px;
              }
            }
            .wishlist-display {
              div {
                padding: 10px;
              }
            }
          }
        }
      }

      .checkin-tracking-modal {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .MuiBox-root {
          width: auto;
          height: auto;
          border: 1px solid black;
          border-radius: 5px;
          background-color: white;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .modal-body {
            text-align: center;
            margin-top: 20px;
            .checkin-timer {
              display: flex;
              justify-content: flex-end;
              margin-top: -20px;
            }

            p {
              margin-bottom: 40px;
              margin-bottom: 20px;
            }
          }
        }
      }

      .transfer-opportunity-modal {
        width: 30%;
        height: 300px;
        position: fixed;
        top: 100px;
        left: 35%; //35% from width
        right: 35%; //35% from width

        .MuiBox-root {
          border: 1px solid black;
          border-radius: 5px;
          background-color: white;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;

          .modal-header {
            padding: 1rem;
            border-bottom: 1px solid #dee2e6;

            h5 {
              margin-bottom: 0;
              line-height: 1.5;
              font-family: 'ARS Maquette Pro' !important;
              font-weight: 400;
            }
          }

          .modal-body {
            padding: 1rem;

            .dropdown-select {
              margin-left: 0px;
            }
          }

          .transfer-modal-footer {
            display: flex;
            margin-top: auto;
            margin-bottom: 1rem;
            margin-left: auto;
            text-align: end;
          }
        }
      }

      .info-modal-content {
        position: absolute;
        // left: 2%; /* Aligns modal to the left */
        width: 50%; /* Adjust width to fit within left screen */
        max-width: 920px; /* Prevents excessive stretching */
        background: white;
        padding: 20px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        z-index: 100;
        display: block; /* Ensures child divs stack */
        background-color: rgb(31, 30, 30);
        color: white;
      }
    }
  }

  //Notepad
  .initialize-notepad {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .MuiIconButton-root {
      flex-direction: column;
      width: 300px;
      height: 300px;

      &:hover {
        background-color: transparent;
        border: 1px solid #d0281b;
        border-radius: 50%;
      }
    }

    svg {
      color: #d0281b;
    }

    span {
      margin-top: 20px;
      color: #d0281b;
      font-weight: bolder;
    }
  }

  .opportunity-notepad {
    user-select: none;

    .MuiGrid-container {
      hr {
        margin: 2rem 0;
      }

      .opportunity-display {
        height: calc(100vh / var(--scale-factor) - 100px);
        overflow-y: scroll;
        margin-top: 1rem;
        padding-right: 0.5rem;

        .title {
          border: 1px solid #d0281b;
          background-color: #d0281b;
          color: white;
          padding: 10px 0px;
          text-align: center;
          border-radius: 5px 5px 0px 0px;
          font-weight: bold;
          font-size: 1rem;
        }

        .MuiPaper-root {
          width: 100% !important;
          padding: 0.5rem;
          border: 1px solid #bcbcbc;
          border-radius: 5px;
          margin-bottom: 0.75rem;

          .MuiAccordionSummary-root {
            &.MuiButtonBase-root {
              width: 100%;
            }

            .MuiAccordionSummary-content {
              font-size: large;
              font-weight: bold;

              &:hover {
                text-decoration: underline;
              }
            }

            svg {
              color: #d0281b;

              &.unread-notepad-icon {
                margin-right: 10px;
              }
            }
          }

          .MuiBox-root {
            height: fit-content;
            min-width: 100px;
            padding: 0px 10px;
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;

            .record-box {
              display: flex;
              flex-direction: column;
              border: 1px solid #bcbcbc;
              border-radius: 5px;
              min-height: 70px;
              align-items: left;
              justify-content: center;
              padding: 0px 10px;

              &.selected {
                border-color: #d0281b;
                background-color: #f9ebe9;
              }

              &:hover {
                background-color: #f9ebe9;
                cursor: pointer;
              }
            }
          }
        }
      }

      .notepad-area {
        margin-top: 15px;
        display: flex;
        flex-direction: column;

        .header {
          background-color: #d0281b;
          color: white;
          padding: 10px 0px;
          font-weight: bold;
          justify-content: center;
          display: flex;
          flex-direction: row;
          border-radius: 5px 5px 0px 0px;
        }

        .body {
          height: calc(100vh / var(--scale-factor) - 260px);
          overflow-y: scroll;
          padding: 5px 10px;

          border: 1px solid #bcbcbc;

          &:focus-visible {
            outline: unset;
          }

          .notes-bubble {
            display: flex;
            flex-direction: column;
            margin-top: 1rem;
            overflow-wrap: anywhere;

            .notes {
              padding: 1rem;
              border-radius: 10px;
              background-color: #f7cbd1;
              font-size: 0.9rem;
              max-width: 70%;
              user-select: text !important;

              &:not(.others) {
                margin-right: auto;
              }

              &.others {
                margin-left: auto;
                background-color: #d3d3d3;
              }
            }

            .timestamp {
              text-align: end;
              font-size: small;
              font-style: italic;
              margin-left: auto;

              &.own {
                margin-right: auto;
                margin-left: 0;
              }
            }
          }
        }

        textarea {
          width: 100%;

          &:focus-visible {
            outline: unset;
          }
        }

        .button-red {
          align-self: center;
        }
      }
    }
  }
}

.potential-customer-component.opportunity-list .popover {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  z-index: 1050; /* This ensures it stays on top of other elements */
}

.menu-wrapper {
  position: relative;
  .menu-box {
    z-index: 300000;
  }
}

.dropdown-modal {
  z-index: 1300;
  margin-left: -13rem;
}

.custom-menu {
  border-radius: 6px;
  min-width: 240px;
  color: rgba(0, 0, 0, 0.54);
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  z-index: 300000;

  .menu-item {
    display: flex;
    justify-content: flex-start;
    padding: 11px 14px;
    border-bottom: 1px solid #e0e0e0;

    &:hover {
      background-color: rgba(208, 40, 27, 0.1);
    }

    .menu-icon {
      color: #666666;
      font-size: 23px;
      margin-right: 10px;
      margin-left: -10px;
    }
  }

  .menu-item:last-child {
    border-bottom: none;
  }
}
