.potential-customer-component {
  button#potential-cust-button {
    width: 200px;
    height: 45px;
  }

  .popover {
    min-width: 1000px;
    // min-height: 60%;
    z-index: 1301 !important;

    .popover-header {
      height: 60px;

      span {
        display: flex;
        height: 100%;
        align-items: center;

        button {
          margin-top: 0px;
        }
      }
    }

    .bottom-button {
      text-align: center;
      margin-top: 20px;
    }

    .MuiTab-textColorPrimary.Mui-selected {
      color: #d0281b !important;
    }

    .input-group {
      display: flex;
      justify-content: space-between;
      // width: 50%; /* Adjust width as needed */
      margin-bottom: 20px;
    }

    .input-field {
      display: flex;
      flex-direction: column;
      width: 48%; /* Adjust width to control the space between the inputs */
    }

    .button-container {
      display: flex;
      justify-content: center; /* Center the button horizontally */
      align-items: center; /* Center the button vertically */
      margin-top: 20px; /* Optional: Add some space between the Tabs and the button */
      height: 100%; /* Adjust this as needed */
      margin-bottom: 20px;
    }
  }
}
