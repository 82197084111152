// Scaling variables
:root {
  --scale-factor: 1;
  --header-height: 130px;
  --sidebar-width: 260px;
  --sidebar-collapsed-width: 60px;
  --viewport-width: calc(100vw / var(--scale-factor));
  --viewport-height: calc(100vh / var(--scale-factor));
  --content-width: calc(var(--viewport-width) - var(--sidebar-collapsed-width));
  --content-width-sidebar-open:
    calc(
      var(--viewport-width) - var(--sidebar-width)
    );
  --content-height: calc(var(--viewport-height) - var(--header-height) - 100px);
}

// Viewport dimension mixins
@mixin viewport-dimensions {
  width: var(--viewport-width);
  height: var(--viewport-height);
}

// Content specific mixins
@mixin content-dimensions {
  width: var(--content-width);
  min-width: var(--content-width);
  // height: var(--content-height);
  // min-height: var(--content-height);
}

@mixin content-dimensions-sidebar-open {
  width: var(--content-width-sidebar-open);
  min-width: var(--content-width-sidebar-open);
  // height: var(--content-height);
  // min-height: var(--content-height);
}

// Mixin for sidebar margin
@mixin sidebar-margin {
  margin-left: var(--sidebar-collapsed-width);
}

// Mixin for content with sidebar margin
@mixin content-with-sidebar {
  @include content-dimensions;
  @include sidebar-margin;
  margin-top: 1rem;
  transition: all 0.3s ease-in-out;
  will-change: margin-left, width;

  .cr-sidebar--open + .cr-header + & {
    @include content-dimensions-sidebar-open;
    margin-left: var(--sidebar-width);
  }
}
