.modal-customer-profile {
  max-width: 0;
  transition: all 0.3s ease-out;

  & > .table {
    background-color: lightgrey;

    > tr > .body {
      background: white;
    }
  }

  .table {
    margin-bottom: 0;
    height: 100%;

    td,
    th {
      border-top: none;
    }

    td.slider > svg {
      padding: 0 !important;
    }
  }

  .slider {
    background-color: lightgrey;
    text-align: center;
    color: black;
    height: 100%;
    font-weight: bold;
    cursor: pointer;
    width: 30px;
    vertical-align: middle;
    padding: 0px;

    &:hover {
      background-color: grey;
      color: white;
    }
  }

  .body {
    display: inline-block;
    height: 100%;
    width: calc(85vw - 32px);
    min-width: 720px;
  }

  &.open {
    transition: all 0.3s ease-out;
    max-width: calc(85vw) !important;
    width: calc(85vw) !important;
    min-width: 740px;
    height: 600px;
    position: fixed;
    top: 100px;
    left: 15%; //25% from width
    z-index: 2000;
  }
}
