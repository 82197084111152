@import '../../styles/scaling';

.cr-header {
  margin-left: 60px;
  position: sticky;
  top: 0px;
  box-shadow: 0px 2px 6px #0000000a;
  z-index: 98;
  user-select: none;

  .cr-sidebar--open + & {
    margin-left: var(--sidebar-width);
  }

  &__nav-right {
    flex-direction: row;
    justify-content: flex-end;
    margin-left: auto;
  }

  &__nav-right-button {
    position: absolute;
    top: 3px;
    //right: 260px;
  }

  .iss-header {
    height: 40px;
    width: 100%;
    min-width: 1000px;
    background-color: white;
    border-bottom: 1px solid #f6f6f6;
    padding: 0.5rem 1rem;
    padding-top: 0px !important;

    &.special {
      border-bottom: none;
    }

    .nav-item {
      margin-top: 8px;
    }

    .nav-link {
      padding: 0px !important;
    }

    .iss-logo {
      color: #d0281b;
      font-weight: 900;
      font-family: ARS Maquette Pro;
      display: inline !important;
      vertical-align: middle;
      margin-right: 0px;
      margin-top: 5px;
      height: 44px;
      object-fit: scale-down;
    }

    .next-to-logo {
      display: flex;
      flex-direction: row;
      height: 100%;
      margin-top: 7px;

      .iss-header-separator {
        height: 30px;
        border-right: 2px solid #343434;
        margin-right: 15px;
        margin-left: 15px;
        //margin-top: 5px;
      }

      .iss-header-storename {
        font-weight: 900;
        font-size: 1.8rem;
        color: #666666;
        margin-right: 10px;
        margin-top: -7px;
        width: max-content;
      }

      .iss-header-switchview {
        color: #d0281b;
        font-size: 20px;
        width: max-content;
        font-weight: bold;
        margin-left: 1.5rem;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .iss-header-staffname {
      color: #333333;
      margin-right: 10px;
      margin-top: 12px;
      width: max-content;
    }

    .opportunity-list-badge {
      margin-top: 12px;
      border: 1px solid #d0281b;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      display: none;
      text-align: center;
      cursor: default;
      user-select: none;
    }

    .iss-header-arrowdown {
      margin-right: 8px;
      color: #e11422;
      font-size: 1.3rem;
      font-weight: bold;
      cursor: pointer;
    }

    .notification-icon {
      cursor: pointer;
      margin: 5px 12px 0px 10px;
      position: relative;

      .click {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
      }

      svg {
        //transform: rotate(45deg);
        color: #343434;
      }

      .badge {
        position: absolute;
        top: -3px;
        right: -4px;
        margin-left: 5px;
        background-color: #d0281b;
        border: none;
        color: #fff;
        font-size: 0.6rem;
        font-weight: 300;
        padding-top: 2px;
      }
    }
  }
}

.header-search {
  background-color: #fff;
  z-index: 999;

  #header-product-search-keyword {
    width: 138px;
    margin-top: 10px;
  }

  #header-product-search-button {
    height: 30px;
    margin-left: 5px;
    display: none;
  }

  &.keyed {
    input {
      width: 250px !important;
    }

    #header-product-search-button {
      display: inline-block !important;
    }
  }
}

.dev-tag {
  display: flex;
  z-index: 999;
  margin-top: 5px;

  .flex {
    display: flex;
  }

  .env {
    position: relative;
    font-size: 0.6rem;
    text-orientation: mixed;
    height: 22px;
    text-align: center;
    padding-top: 3px;
    padding-left: 5px;
    padding-right: 5px;
    width: 50px;
  }

  .environmentName {
    &.local {
      background-color: lightgreen;
      color: #fff;
    }

    &.iss {
      background-color: yellow;
    }

    &.dev {
      background-color: red;
      color: #fff;
    }

    &.qa {
      background-color: pink;
    }
  }

  .environmentDB {
    &.local {
      background-color: red;
      color: #fff;
      display: inline-block;
    }

    &.iss {
      background-color: red;
      color: #fff;
      display: inline-block;
    }

    &.dev {
      background-color: red;
      color: #fff;
      display: inline-block;
    }

    &.qa {
      background-color: red;
      color: #fff;
      display: inline-block;
    }
  }

  .btn-tablet-view {
    color: #333333;
    border: none;
    box-shadow: none;
    background: none;
    position: relative;

    &:hover {
      color: #af2116;
      outline: none;
      /*1px solid #AF2116;*/
    }

    &:focus {
      outline: 1px solid #af2116;
    }

    div {
      white-space: nowrap;
      display: inline-block;
      font-size: 0.8rem;
    }

    svg {
      margin-top: -10px;
      margin-left: -5px;
    }
  }
}

.zoom-section {
  display: flex;
  flex-direction: row;
  float: right;

  button {
    overflow: hidden;
    background-color: transparent;
    width: 40px;
    height: 27px;
    border: 1px solid #d4281c;
    font-weight: bolder;

    &:hover {
      background-color: #af2116;
      border: 1px solid #af2116;
      color: white;
    }

    &:nth-child(1) {
      border-radius: 20px 0px 0px 20px;
    }

    &:nth-child(2) {
      border-radius: 0px 20px 20px 0px;
    }
  }
}

.user-profile-popover > .popover {
  top: 40px !important;
  left: unset !important;
  right: 0px !important;
  will-change: unset !important;
  transform: none !important;

  .list-group-item-action {
    cursor: pointer;
  }
}
