.spinner {
  color: black;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0;

  span {
    padding-left: 10px;
  }
}
