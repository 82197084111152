.communication-collaboration {
  .not-ack-stats {
    float: right;
    margin-top: -4px;
    color: #343434;
    font-size: 0.9rem;

    .title {
      font-weight: bold;
      width: auto;
      text-align: right;
      padding-right: 5px;
      display: inline-block;
      opacity: 0.75;
      padding-top: 10px;
    }
  }

  .communication-filters {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    position: unset;
    top: 0;
    background-color: white;
    padding-bottom: 5px;
    padding-right: 24px;
    padding-left: 24px;

    .filter-label {
      height: 24px;
    }

    .dropdown-select {
      margin-left: 0px;
      margin-top: 0px;

      [class$='control'] {
        cursor: pointer;
      }

      [class$='option'] {
        cursor: pointer;
      }

      [class$='menu'] {
        z-index: 99;
      }
    }

    .communication-filter-type-multiselect {
      input {
        height: auto !important;
      }

      .custom-option {
        display: flex;
        flex-direction: row;
        cursor: pointer;

        &.selected {
          background: #ff7285 !important;
          color: white;
        }

        &:not(.selected) {
          &:hover,
          &:active,
          &:focus {
            background: #f9ebe9 !important;
          }
        }

        .Mui-checked {
          color: floralwhite;
        }

        .label {
          align-self: center;
        }
      }
    }

    .toggle-data-state {
      @media only screen and (max-width: 1279px) {
        margin-left: auto;
        margin-right: 10px;
      }

      @media only screen and (min-width: 1280px) {
        margin-left: auto;
        margin-right: 30px;
      }

      button {
        height: 42px;
        min-width: 100px !important;

        &:nth-child(1) {
          border-radius: 20px 0px 0px 20px;
        }
        &:nth-child(3) {
          border-radius: 0px 20px 20px 0px;
        }
      }
    }
  }

  .container {
    max-width: 100%;

    .no-data {
      text-align: center;
      font-weight: bold;
    }

    //New design
    .inner-container {
      display: flex;
      flex-direction: row;
      border: 1px solid #bcbcbc;
      border-radius: 1rem;
      min-height: 600px;
      max-height: 600px;

      .outer-box-left {
        box-sizing: border-box;
        padding: 0px 20px 1rem 20px;
        border-right: 1px solid #bcbcbc;
        max-height: inherit;
        min-height: inherit;
        overflow: hidden;
        overflow-y: scroll;

        .top-row-filter {
          display: flex;
          flex-direction: column;
          position: sticky;
          top: 0px;
          height: 45px;
          background-color: #fff;
          border-radius: 5px 0px 0px 0px;

          button#mark-all-read {
            min-width: fit-content;
            width: fit-content;
            height: 35px;
            margin-top: 5px;
          }

          > div {
            display: flex;
            flex-direction: row;
            margin-left: auto;
            width: auto;
            height: 45px;

            span {
              width: fit-content;
              align-self: center;
              padding: 0px 12px;

              &.active {
                color: #d0281b;
                font-weight: bold;
              }

              &:hover {
                color: #d0281b;
                text-decoration: underline;
                user-select: none;
                cursor: pointer;
              }
            }
          }
        }

        .list-rows {
          margin: 10px 0px;

          &:hover,
          &.selected {
            background-color: #f9ebe9;
          }

          &.row > [class^='col-'] {
            margin-bottom: 0;
          }

          .icons {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;

            .comm-type-icon {
              margin: 0px auto 0px auto;
              text-align: center;

              > svg {
                color: rgb(78, 221, 130);
              }
            }

            //Read icon
            .read-circle {
              &:hover {
                cursor: pointer;
                transform: scale(1.1);
              }

              &.unread {
                color: #0072c6;
                fill: #0072c6;
              }

              &.read {
                color: #bcbcbc;
                fill: transparent;

                &:hover {
                  color: #0072c6;
                  fill: #0072c6;
                }
              }
            }

            //Pinned icon
            .pinned {
              display: flex;
              align-self: center;
              margin-left: auto;
              color: #d0281b;
              cursor: pointer;
            }

            //Checkbox icon
            .checkbox-icon {
              display: flex;
              flex: 0;
              align-self: center;
              margin-left: auto;

              &:hover {
                cursor: pointer;
                color: white;
                border-radius: 50%;
              }

              .Mui-checked {
                color: green;
              }

              > span {
                padding: 0px;
                position: unset;
                background-color: transparent;

                input {
                  position: unset;
                }
              }
            }
          }

          .listing-detail {
            &:hover {
              cursor: pointer;
            }
            span.bold {
              display: contents;
              font-weight: bold;
            }
          }

          .create-date {
            display: flex;
            flex-direction: row-reverse;
            font-size: smaller;
            margin-right: 10px;

            &::after {
              content: '';
              border-bottom: 1px solid #bcbcbc;
              width: calc(100% - 15px);
              display: block;
            }
          }
        }
      }

      .outer-box-right {
        max-height: inherit;
        min-height: inherit;
        overflow: hidden;
        overflow-y: scroll;
        padding-left: 20px;
        display: flex;
        flex-direction: column;

        .landing-summary {
          display: flex;
          flex-direction: column;
          width: 70%;
          height: 70%;
          align-items: center;
          justify-content: center;
          margin: auto auto;
          border: 1px solid #bcbcbc;
          border-radius: 1rem;
          font-weight: bolder;
          font-size: larger;

          div {
            margin-bottom: 2rem;
          }
        }

        .conversation {
          margin-top: 10px;
          border: 1px solid #bcbcbc;
          border-radius: 1rem;
          padding: 1rem;

          &:last-child {
            margin-bottom: 1rem;
          }

          .bold {
            font-weight: bold;
          }

          .header {
            display: flex;
            flex-direction: row;
            height: fit-content;
            margin: 1rem 0px;

            .header-detail {
              display: flex;
              flex-direction: column;
            }

            .action-icons {
              display: flex;
              margin-left: auto;
              align-items: center;
              width: fit-content;

              svg {
                margin-right: 1rem;
                cursor: pointer;

                &:hover {
                  transform: scale(1.1);
                }
              }
            }
          }

          .customer-info {
            table {
              width: 100%;
              text-align: center;
            }
          }

          .message-body {
            white-space: pre-line;
            overflow: hidden;
          }

          .hyperlink-highlight {
            color: #0072c6;
            cursor: pointer;
            font-weight: bold;

            &:hover {
              text-decoration: underline;
            }
          }

          .order-number-highlight {
            color: #0072c6;
            font-weight: bold;
            border: none;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

//Overwrite potential customer positioning without changing re-usable component
.potential-customer-component .popover {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  z-index: 1050; /* This ensures it stays on top of other elements */
}

// Create New Modal Style
.modal-absolute-center {
  position: relative;
  top: 85px;
}

.modal-communication {
  max-width: 910px !important;
  padding-bottom: 85px;

  input {
    max-width: 200px;
    margin-right: 10px;

    &:focus {
      box-shadow: none !important;
      border-color: #3f51b5 !important;
      border-width: 2px;
    }
  }

  input[type='email'] {
    // max-width: 620px;
    max-width: 420px;
    // margin-top: 10px;
  }

  .info {
    margin: 16px 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .dropdown-select {
      margin-top: 0;
      margin-left: 0;
      min-width: 200px;
      margin-right: 10px;

      > div {
        width: 200px;
      }

      input {
        height: auto !important;
      }
    }

    input {
      height: 45px !important;

      &#order-number {
        margin-top: 10px;
      }
    }

    .parent-group-multiselect {
      width: 200px !important;
      height: 45px;

      &.disabled {
        .dropdown-select {
          [class$='singleValue'] {
            width: -webkit-fill-available;
          }
        }
      }

      .recipient-multiselect {
        input {
          height: auto !important;
        }

        .custom-option {
          display: flex;
          flex-direction: row;
          cursor: pointer;

          &.selected {
            background: #ff7285 !important;
            color: white;
          }

          &:not(.selected) {
            &:hover,
            &:active,
            &:focus {
              background: #f9ebe9 !important;
            }
          }

          .Mui-checked {
            color: floralwhite;
          }

          .label {
            align-self: center;
          }

          .exclamation-icon {
            align-self: center;
            max-height: 20px;
            max-width: 20px;
            cursor: pointer;
            color: red;
            margin-left: auto;
            margin-right: 5px;
          }
        }
      }

      button {
        min-width: 100%;
        min-height: 100%;
        height: 45px;

        border-radius: 4px;
        color: rgba(0, 0, 0, 0.87);
        border: 1px solid #ced4da;
        box-shadow: none;

        > span:first-child {
          font-size: 1rem;
          -webkit-box-align: center;
          align-items: center;
          align-self: center;
          flex: 1 1 auto;
          margin: 0px;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        > span:nth-child(2) {
          > svg {
            height: 34px;
            width: 34px;
          }
        }
      }

      .group-multiselect {
        min-width: max-content;

        input {
          height: auto !important;
        }
      }
    }

    .potential-customer-component {
      margin-top: -10px;
    }
  }

  .follow-up-date {
    max-width: 400px;
    border: 1px solid #ced4da;
    background-clip: border-box;
    border-radius: 5px;
    margin: 10px auto;

    > div {
      padding: 10px 10px;
      justify-content: center;
    }

    .shortcut {
      text-align: center;

      span {
        display: inline-block;
        margin: 3px 5px;
        height: 30px;
        padding: 0px 10px;
        padding-top: 3px;
        border-radius: 5px;
        background-color: #e0e0e0;
        cursor: pointer;

        &.dateselected {
          font-weight: bold;
        }
      }
    }
  }

  .customer_journey_stage {
    display: flex;
    align-items: center;
  }

  .view-textarea {
    width: 100%;
    height: 100%;
    border: 0;
    margin: 0;
    display: inline-flex;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;

    .textarea-show {
      margin-left: 16px;
      margin-right: 10px;
      margin-top: 10px;
      width: auto;
      background-color: #f5f5f5;

      &:focus-visible {
        outline: none;
        box-shadow: none !important;
        border-color: #3f51b5 !important;
        border-width: 2px;
      }
    }
  }

  .red-asterisk {
    color: red;
    font-weight: bold;
    position: relative;
    top: -15px;
    left: -205px;
    width: 0;
  }
}
