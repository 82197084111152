.host-duty {
  display: flex;
  margin-top: 8px;
  width: 100%;
  justify-content: center;

  .current-host {
    color: #d0281b;
    background-color: transparent;
    text-decoration: none;
    font-size: 20px;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .MuiBox-root.hod-popover {
    width: auto;
    padding: 2rem;
    display: flex;
    flex-direction: row;

    .current-manager-host {
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    .other-manager-host {
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    .next-host-information-popup div {
      display: flex;
      flex-direction: row;
      text-align: left;
      margin-bottom: 1rem;
      padding-bottom: 5px;
      border-bottom: 3px solid #dadada;

      span {
        margin-right: 10px;
      }

      .delete-icon {
        margin-left: auto;
        color: rgba(0, 0, 0, 0.54);
        align-items: flex-start;
        padding: 0px !important;

        &:hover {
          color: #d0281b;
        }
      }
    }
  }
}

.host-duty-modal {
  min-width: 50%;
  height: 380px;
  position: fixed !important;
  top: 30% !important;
  left: 25% !important; //25% from width
  right: 25% !important; //25% from width
  z-index: 1301 !important;

  &:focus-visible {
    outline: unset;
  }

  .MuiBox-root {
    border: 1px solid black;
    border-radius: 5px;
    background-color: white;
    width: 100%;
    height: 100%;

    &:focus-visible {
      outline: unset;
    }

    .modal-header {
      padding: 1rem;
      border-bottom: 1px solid #dee2e6;

      h5 {
        margin-bottom: 0;
        line-height: 1.5;
        font-family: 'ARS Maquette Pro' !important;
        font-weight: 400;
      }
    }

    .modal-body {
      padding: 1rem;
      display: flex;
      flex-direction: row;
      height: calc(100% - 65px);
      overflow: hidden;

      .modal-body-form {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-right: 10px;

        .MuiFormGroup-root {
          justify-content: center;
          margin-top: 20px;

          .MuiFormControlLabel-root {
            margin-left: 2rem;
            height: 25px;
            width: 75px;

            .MuiButtonBase-root.Mui-checked {
              color: #d0281b !important;
            }

            .MuiTypography-root {
              margin-left: 0.5rem;
            }
          }
        }

        .time-picker {
          display: flex;
          flex-direction: row;
          margin-top: auto;
          justify-content: space-evenly;
        }

        .footer-button {
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin-top: auto;
        }
      }

      .modal-body-next-host {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        overflow-y: scroll;

        width: 40%;
      }
    }
  }
}

.host-duty-modal-forced {
  min-width: 50%;
  height: 380px;
  position: fixed !important;
  top: 30% !important;
  left: 25% !important; //25% from width
  right: 25% !important; //25% from width

  &:focus-visible {
    outline: unset;
  }

  .MuiBox-root {
    border: 1px solid black;
    border-radius: 5px;
    background-color: white;
    width: 100%;
    height: 100%;

    &:focus-visible {
      outline: unset;
    }

    .modal-header {
      padding: 1rem;
      border-bottom: 1px solid #dee2e6;

      h5 {
        margin-bottom: 0;
        line-height: 1.5;
        font-family: 'ARS Maquette Pro' !important;
        font-weight: 400;
      }
    }

    .modal-body {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      height: calc(100% - 65px);
      overflow: hidden;

      .footer-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: auto;
      }
    }
  }
}

.host-duty-acknowledge {
  width: 540px;
  height: 200px;
  position: fixed !important;
  top: 30% !important;
  left: calc(50% - 200px) !important;

  &:focus-visible {
    outline: unset;
  }

  .MuiBox-root {
    border: 1px solid black;
    border-radius: 5px;
    background-color: white;
    width: 100%;
    height: 100%;

    &:focus-visible {
      outline: unset;
    }

    .modal-header {
      padding: 1rem;
      border-bottom: 1px solid #dee2e6;

      h5 {
        margin-bottom: 0;
        line-height: 1.5;
        font-family: 'ARS Maquette Pro' !important;
        font-weight: 400;
      }
    }

    .modal-body {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      height: calc(100% - 65px);
      overflow: hidden;
      text-align: center;

      .footer-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: auto;
      }
    }
  }
}

.MuiPickersPopper-root {
  width: 260px;

  .MuiMultiSectionDigitalClock-root,
  .MuiMultiSectionDigitalClockSection-root {
    justify-content: space-evenly;

    text-align: center;
    width: calc(100% - 5px);
    height: fit-content;
    overflow: auto;

    .Mui-selected {
      background-color: #d0281b !important;
    }

    li.MuiButtonBase-root {
      padding: 0px;
      margin: 0px;
      width: 100%;
      height: 45px;
    }
  }

  .MuiDialogActions-root {
    height: 50px;

    button {
      color: black;
      padding: 6px 8px;

      &:hover {
        background-color: #d0281b;
        color: white;
        border-radius: 5px;
      }
    }
  }
}
