.avatarInitial {
  border-radius: 50%;
  background-color: #343434;
  color: #c4c4c4;
  text-align: center;
  padding-top: 3px;
  display: inline-block;
  cursor: pointer;
  margin-top: 3px;
}
