.extTest {
  legend {
    margin-left: 10px;
  }
  label {
    margin-left: 10px;
    font-size: 1rem;
    input {
      width: 150px;
      font-size: 1rem;

      &.long {
        width: 500px;
      }
    }
  }
  .frameborder {
    border: 1px solid gray;
    margin: 0 auto;
    left: 0px;
    right: 0px;
    display: block;
  }
}
