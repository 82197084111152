.drawer-container {
  .drawer-toggle-bar {
    font-size: larger;
    text-align: center;
    cursor: pointer;
    background-color: #fff;
  }
}

.iss-gauge-card-row {
  height: 75px;
  background-color: #fff;
  box-shadow: 0px 2px 6px #0000000a;
  position: relative;
  width: calc(100% - 0px) !important;
  margin-left: 0px;
  margin-bottom: 0px !important;
  min-width: 1000px;
  top: 0px;
  table-layout: fixed;
  padding: 10px 15px 10px 20px;
  border-top: 1px solid grey;

  &.privacy-on {
    position: absolute;
    top: 40px;
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6px);
    border-top: 0px;
    z-index: 99999;

    &.hide {
      display: none;
    }
  }

  .iss-gauge-card-item {
    position: relative;
    width: 24%;

    .text {
      position: absolute;
      /*top: 50%;
            transform: translate(0, -50%);*/
      top: 17px;
      left: 80px;
      text-align: center;
      width: 100px;

      .title {
        font-size: 0.8rem;
        font-weight: 500;
        line-height: 100%;

        span {
          white-space: nowrap;
        }

        .subbold {
          font-size: 0.8rem;
          font-weight: 300;

          b {
            font-size: 0.8rem;
            font-weight: bold;
          }
        }
      }

      .value {
        font-size: 0.9rem;
        font-weight: bold;
      }
    }

    .float {
      //z-index: 2;
      cursor: pointer;
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translate(0, -50%);
      width: 60px;
      text-align: center;
      vertical-align: middle;

      .precent {
        font-size: 0.9rem;
        font-weight: bold;
        text-align: center;
      }

      .rank {
        margin-top: 4px;
        font-size: 0.8rem;
        font-weight: bold;
        text-align: center;
        color: #666666;
        line-height: normal;
      }

      .rank-popper {
        //margin-top: 20px !important;
        //margin-left: 55px !important;

        background-color: white;
        border: 1px solid gray;
        border-radius: 5px;
        min-width: 400px;
        max-width: 400px;
        position: absolute;
        z-index: 98;
        top: 55px;
        max-height: 400px;
        overflow-y: scroll;
        padding: 10px;

        table {
          width: 100%;

          tr {
            td:nth-child(1),
            th:nth-child(1) {
              width: 10%;
            }
            td:nth-child(2),
            th:nth-child(2) {
              width: 60%;
            }
            td:nth-child(3),
            th:nth-child(3) {
              width: 30%;
            }
          }

          thead {
            position: sticky;
            top: -10px;
            bottom: 0;
            vertical-align: middle;
            box-shadow: 0 4px 2px -2px gray;
            background-color: white;

            th {
              padding: 0;
              height: 48.5px;
            }
          }

          tbody {
            tr {
              border-bottom-style: hidden;

              td {
                padding: 2px 0px;
              }

              &.own {
                position: sticky;
                top: 40px;
                bottom: 0;
                box-shadow:
                  0 4px 8px 1px rgba(0, 0, 0, 0.3),
                  0 6px 20px 1px rgba(0, 0, 0, 0.3);
                background-color: #f2f2f2;
                //border: black solid 1px;

                td {
                  font-weight: bold !important;
                }
              }
            }
          }
        }
      }
    }

    .circle {
      cursor: pointer;
      position: absolute;
      top: 7px;
      left: 15px;
      transform: rotateZ(180deg);
    }
  }

  .iss-gauge-card-item:first-child {
    padding-left: 15px;
  }

  .iss-gauge-card-item::after {
    content: ' ';
    height: 63px;
    width: 1px;
    position: absolute;
    top: 6px;
    right: 0px;
    border-right: 2px solid #e0e0e0;
  }

  .iss-gauge-card-item:last-child::after {
    display: none;
  }
}

.iss-gauge-card-row.mini {
  height: 40px;
  position: fixed;
  top: 40px;
  z-index: 999;
  width: calc(100% - 60px) !important;

  .text {
    position: absolute;
    top: 13px;
    left: 50px;
    text-align: left;
    width: calc(100% - 52px);

    .title {
      float: left;
      font-size: 0.8rem;
      font-weight: 500;

      br {
        display: none;
      }

      span {
        white-space: nowrap;
      }

      span::before {
        content: ' ';
      }
    }

    .value {
      float: right;
      font-size: 0.8rem;
      font-weight: 500;
      padding-right: 10px;
      line-height: 100%;
    }
  }

  .float {
    width: 30px;
    height: 30px;

    .rank {
      margin-top: 8px;
      font-size: 0.7rem;
      font-weight: 500;

      br {
        display: none;
      }
    }
  }

  .circle {
    top: 5px;
  }

  .iss-gauge-card-item::after {
    height: 40px;
    top: 0px;
  }

  &::after {
    content: ' ';
    height: 60px;
    position: relative;
  }
}
